import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useRouter } from '../../hooks/router';
import Api from '../../services/Api';
import './iframe.scss';
import draftToHtml from 'draftjs-to-html';
import { useAuth } from '../../hooks/auth/Auth';

export default function IFrame ({ route }) {
  const auth = useAuth();
  const router = useRouter();
  const [classe, setClasse] = useState([]);

  useEffect(() => {
    Api.get(`/classes/${route.params.slug}`)
      .then(response => {
        setClasse(response.data);
      })
      .catch(error => {
        console.log(error);
        alert('Une erreur est survenue.');
      })
  }, [route.params.slug]);

  function addToCart(classe) {
    let cart = localStorage.getItem('bernCartClasses');
    if (cart === null) {
      cart = [];
    } else {
      cart = JSON.parse(cart);
    }
    if (cart.findIndex(c => c.id === classe.id) === -1) {
      cart.push({
        id: classe.id
      });
    }

    localStorage.setItem('bernCartClasses', JSON.stringify(cart));

    router.goTo('checkout');
  }

  return (
    <div className="iframe">
      <div className="page">
        <div className="classe">
          <div className="left">
            <div className="image" style={{ backgroundImage: `url(${classe.image ? classe.image.url : ''})` }}>
              <img src={classe.image ? classe.image.url : ''} style={{ width: '100%', height: '100%' }} alt=""/>
              <div className="dates">
                { classe.firstClasseAt &&
                  <>
                    <div className="day">{moment(classe.firstClasseAt).format('DD')}</div>
                    <div className="month">{{ 1: 'JAN', 2: 'FEV', 3: 'MAR', 4: 'AVR', 5: 'MAI', 6: 'JUN', 7: 'JUL', 8: 'AOU', 9: 'SEP', 10: 'OCT', 11: 'NOV', 12: 'DEC' }[moment(classe.firstClasseAt).format('M')]}</div>
                    <div className="year">{moment(classe.firstClasseAt).format('YYYY')}</div>
                  </>
                }

                { classe.firstClasseAt && classe.lastClasseAt &&
                  <div className="separator" />
                }

                { classe.lastClasseAt &&
                  <>
                    <div className="day">{moment(classe.lastClasseAt).format('DD')}</div>
                    <div className="month">{{ 1: 'JAN', 2: 'FEV', 3: 'MAR', 4: 'AVR', 5: 'MAI', 6: 'JUN', 7: 'JUL', 8: 'AOU', 9: 'SEP', 10: 'OCT', 11: 'NOV', 12: 'DEC' }[moment(classe.lastClasseAt).format('M')]}</div>
                    <div className="year">{moment(classe.lastClasseAt).format('YYYY')}</div>
                  </>
                }
              </div>
            </div>
            { 
              classe.registrationOpen
              && (classe.registrationOpenStartAt ? moment(classe.registrationOpenStartAt).isSameOrBefore(moment()) : true)
              && (classe.registrationOpenEndAt ? moment(classe.registrationOpenEndAt).isSameOrAfter(moment()) : true)
              && !classe.isFull
              && !classe.alreadyRegistered
            ? (
              <>
                <br/>
                <button onClick={() => addToCart(classe)}>S'inscrire</button>
              </>
            ) : (
              <></>
            )}
            { classe.isFull &&
              <div className="warning">
                Complet
              </div>
            }
            { classe.alreadyRegistered &&
              <div className="warning">
                Vous êtes déjà inscrit à ce cours
              </div>
            }

            <br/>
            <h3>Tarifs :</h3>
            <div style={{ marginBottom: '10px' }}>
              Tarif individuel : {classe.individualPriceTtc || 'n/a'}€
            </div>
            <div>
              Tarif jeune - 30 ans : {classe.youthPriceTtc || 'n/a'}€
            </div>
            <br/>

            <div style={{ marginBottom: '20px' }}>
              Pour les cours financés par un employeur, une association, ou autre personne morale, le tarif INSTITUTION est appliqué. Veuillez cliquer <a style={{ color: '#4B1D4E', textDecoration: 'underline' }} href="https://www.collegedesbernardins.fr/formation/informations-pratiques-cours-publics#tarif" target="_blank" rel="noopener noreferrer">sur cette page</a> pour connaître les modalités
            </div>

            { classe.publicMoreInformations &&
              <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(decodeURIComponent(classe.publicMoreInformations))) }}></div>
            }
          </div>

          <div className="infos">
            { classe.surtitle &&
              <div className="over-title">
                {classe.surtitle}
              </div>
            }
            <div className="title">{classe.number} - {classe.name}</div>
            { classe.subName &&
              <>
                <div className="sub-title">{classe.subName}</div>
                <hr/>
              </>
            }
            <div className="content">
              { classe.publicDescription &&
                <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(decodeURIComponent(classe.publicDescription))) }}></div>
              }
              {/* <h3>Séances</h3>
              <p>
                Jeudi 10h-12h - 24 séances
                <br/>
                Premier cours : jeudi 24 septembre 2020
              </p>
              <p className="intro">La Bible est une bibliothèque dans laquelle on se perd facilement si personne ne nous guide (Ac 8, 31).</p>

              <p>
                Ce cours propose une familiarisation avec la diversité de la Bible par une initiation à son langage, son contexte, aux thèmes théologiques fondamentaux qui ont présidé à sa rédaction. Ainsi, en appréhendant de nombreux textes bibliques choisis pour leur pertinence, le cours s’attachera à montrer que l’Alliance donne son unité à ce vaste ensemble rassemblant mille ans de littérature. Au fur et à mesure de la lecture, les éléments historiques et géographiques seront présentés aux étudiants ainsi que différentes méthodes d’analyse.
              </p>
              <em>
                La première année est consacrée à l’Ancien Testament, la seconde à la lecture d’un évangile
              </em> */}
            </div>
          </div>
        </div>
        { classe.professors && classe.professors.length > 0 &&
          <div className="professor">
            <div className="with">
              Avec :
            </div>
            
            {
              classe.professors.sort((a, b) => {
                if (a.professor.user.firstname.toLowerCase() > b.professor.user.firstname.toLowerCase()) {
                  return 1;
                }
                if (a.professor.user.firstname.toLowerCase() < b.professor.user.firstname.toLowerCase()) {
                  return -1;
                }
                return 0;
              }).map(({ professor }) => (
                <div className="wrapper">
                  <div className="image" style={{ backgroundImage:  `url(${professor.profilePicture ? professor.profilePicture.url : ''})` }}>
                  </div>
                  <div className="infos">
                    <div className="top">
                      <a target="_blank" rel="noopener noreferrer" href={professor.profileUrl} className="name">{professor.user.firstname} {professor.user.lastname}</a>
                      { professor.function &&
                        <div className="quality">
                          {professor.function}
                        </div>
                      }
                    </div>
                    { professor.profileUrl &&
                      <div className="bottom">
                        <a target="_blank" rel="noopener noreferrer" href={professor.profileUrl}>Voir la fiche</a>
                      </div>
                    }
                  </div>
                </div>
              ))
            }
          </div>
        }
      </div>
      <div className="menu">
        { auth.isAuthenticated ? (
          <>
            { auth.user ? (
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br/><br/>
                <Link className="light" to="dashboard" target>Mon compte étudiant</Link>
                <span style={{ cursor: 'pointer', textDecoration: 'underline', marginTop: '5px', display: 'block' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            ) : (
              <>Chargement</>
            )}
            <br />
          </>
        ) : (
          <>
            <Link to="dashboard" query={{ redirect: encodeURIComponent(`iframe/classe/${classe.id}`) }}>
              Se connecter à mon compte étudiant
            </Link>
            <Link to="register" query={{ redirect: encodeURIComponent(`iframe/classe/${classe.id}`) }}>
              Créer mon compte étudiant
            </Link>
          </>
        )}
        <hr/>
        <br />
        <Link to="checkout">Voir mon panier</Link>
        <br/>
        { classe && (classe.year === null || classe.year <= 2021) ? (
          <>
            <Link to="iframe" params={{ semester: 'first' }}>
              Cours du 1er semestre
            </Link>
            <Link to="iframe" params={{ semester: 'second' }}>
              Cours du 2nd semestre
            </Link>
            <Link to="iframe-ccdej">
              Cours CCDEJ
            </Link>
          </>
        ) : (
          <>
            <Link to="iframe-year" params={{ slug: `first-${classe.year}-${parseInt(classe.year, 10) + 1}` }}>
              Cours du 1er semestre
            </Link>
            <Link to="iframe-year" params={{ slug: `second-${classe.year}-${parseInt(classe.year, 10) + 1}` }}>
              Cours du 2nd semestre
            </Link>
            <Link to="iframe-ccdej-year" params={{ year: classe.year }}>
              Cours CCDEJ
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
