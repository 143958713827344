import React, { useState, useEffect, useContext, createContext, useRef } from "react";
import { createBrowserHistory } from 'history';

import { queryToUrl } from './utils';

const history = createBrowserHistory();

const routerContext = createContext();
routerContext.displayName = 'Router';

export function ProvideRouter({ children, routes }) {
  const router = useProvideRouter(routes);
  return <routerContext.Provider value={router}>{children}</routerContext.Provider>;
}

export const useRouter = () => {
  return useContext(routerContext);
};

export const withRouter = Component => {
  return (
    <routerContext.Consumer>
      {context => <Component route={context} />}
    </routerContext.Consumer>
  );
};

function useProvideRouter(routesConfig) {
  const [route, setRoute] = useState(false);
  const eventListeners = useRef({
    'routeChanged': [],
  });
  
  useEffect(() => {
    // Default route
    try {
      setRoute(getRouteFromUrl(window.location.pathname, routesConfig, window.location.search));
    } catch (error) {
      setRoute(null);
    }
    const unlisten = history.listen(() => {
      try {
        setRoute(getRouteFromUrl(window.location.pathname, routesConfig, window.location.search));
      } catch (error) {
        setRoute(null);
      }
    });
    return () => unlisten();
  }, []);

  useEffect(() => {
    fireEvent('routeChanged', { route });
  }, [route]);

  const onRouteChanged = (listener) => {
    eventListeners.current.routeChanged.push(listener);
  };

  const fireEvent = (eventName, params) => {
    if (eventListeners.current[eventName]) {
      eventListeners.current[eventName].forEach((listener) => {
        listener(params);
      });
    }
  }

  const goBack = () => {
    // @todo Add a param to detect if it is still in the app, otherwise, go to default route (like dashboard)
    // ^ param will be used by full page by example
    history.goBack();
  };
  
  const goForward = () => {
    history.goForward();
  };
  
  const goTo = (routeName, params = {}, query = {}, replace = false) => {
    const newRoute = _findRoute(0, routeName.split('/'), routesConfig, params);
    setRoute(route);
    history[replace ? 'replace' : 'push'](getUrl(newRoute, query));
  };
  
  const getRoute = (routeName, params = {}, query = {}) => {
    return _findRoute(0, routeName.split('/'), routesConfig, params);
  };
  
  const getRouteFromUrl = (urlPath, routesConfig, queryString = '') => {
    let route = routesConfig
      .find(page => {
        return (
          new RegExp(`^${
            page.url//.replace('*', '[a-zA-Z0-9-]{0,}')
            .replace(/:[^/]*/, '[a-zA-Z0-9-:+_]{1,}') 
              + /* for sub route */ ( page.children ? '(.*)?' : '$')
          }$`)
        )
        .test(urlPath)
      });

    route = Object.assign({}, route);

    if (!route) {
      const defaultRoute = routesConfig.find(route => route.name === '_default') 
      if (defaultRoute) {
        return defaultRoute;
      }
      throw new Error(`No route found for "${urlPath}"`);
    }
    
    const params = route.url
      .split('/')
      .map((value, cpt) => ({ cpt, value }))
      .filter(({ value }) => value[0] === ':')
      .reduce((acc, param) => Object.assign({}, acc, { [param.value.replace(':', '')]: urlPath.split('/')[param.cpt] } ), {});
      
    if (route.children !== undefined) {
      const realUrl = Object.keys(params).reduce((realUrl, keyParam) => realUrl.replace(`:${keyParam}`, params[keyParam]), route.url);
      route.subRoute = getRouteFromUrl(urlPath.replace(realUrl, ''), route.children, window.location.search);
    }

    if (queryString) {
      route.query = {};
      const search = new URLSearchParams(queryString);
      for(var pair of search.entries()) {
        if (pair[1] === '') {
          pair[1] = true;
        } else if (pair[0] !== 'search' && pair[1].match(/,/) !== null) {
          pair[1].split(',');
        }
        route.query[pair[0]] = pair[1];
      }
    }
  
    return Object.assign({}, route, { params });
  };
  
  const getUrl = (route, query) => {
    let { url } = route;
    if (route.params) {
      Object.entries(route.params).forEach(([key, value]) => {
        url = url.replace(`:${key}`, value);
      });
    }
  
    if (route.subRoute) {
      url += getUrl(route.subRoute);
    }
  
    return url + (query ? queryToUrl(query) : '');
  };

  const _findRoute = (cpt, routeKeys, routeList, params = {}) => {
    let route = routeList.find(routeItem => routeItem.name === routeKeys[cpt]);
    if (!route) {
      throw new Error(`No route found for "${routeKeys.join('/')}"`);
    }
  
    route = Object.assign({}, route);
  
    Object.keys(params).forEach(paramKey => {
      if (route.url.match(new RegExp(`:${paramKey}`))) {
        if (route.params === undefined) {
          route.params = {};
        }
        route.params[paramKey] = params[paramKey];
      }
    });
  
    if (route.children !== undefined) {
      if (cpt === routeKeys.length - 1) {
        routeKeys.push('_default');
      }
      const subRoute = _findRoute(cpt+1, routeKeys, route.children, params);
      route.subRoute = subRoute;
    }
  
    return route;
  }

  const setQuery = (queryString) => {
    history.replace(getUrl(route) + queryToUrl(queryString));
  }

  const goToPageUrl = (url) => {
    history.replace(url);
  }
  
  return {
    route,
    goTo,
    goBack,
    goForward,
    getRoute,
    getRouteFromUrl,
    getUrl,
    setQuery,
    queryToUrl,
    onRouteChanged,
    goToPageUrl,
  };
};
