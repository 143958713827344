import { useCallback, useEffect, useRef, useState } from "react";
import Api from "../../services/Api";
import { useForm } from "../form";

export default function useList(entity, defaultFilters = {}, noAuthorization = false) {
  const filters = useForm({ ...defaultFilters });
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const noMore = useRef(false);
  const isLoadingInternal = useRef(false);
  const pageInternal = useRef(1);
  const source = useRef(null);

  const load = useCallback(async (refreshing) => {
    if ((noMore.current || isLoadingInternal.current) && !refreshing) return;
    isLoadingInternal.current = true;
    setIsLoading(true);
    if (refreshing) {
      noMore.current = false;
      pageInternal.current = 1;
      setPage(1);
      setItems([]);
      if (source.current) {
        source.current.cancel('refresh list');
      }
    }
    else {
      pageInternal.current++;
      setPage(prevPage => prevPage++);
    }
    source.current = Api.createRequestToken();
    const response = await Api.findAll(entity, { ...filters.data, page: pageInternal.current }, { source: source.current, noAuthorization });
    if (response[entity].length === 0) {
      noMore.current = true;
    }
    setItems(prevItems => ([ ...prevItems, ...response[entity] ]));
    isLoadingInternal.current = false;
    setIsLoading(false);
  }, [entity, filters.data]);

  useEffect(() => {
    load(true);
  }, [filters.data, entity]);

  function setItem(item) {
    setItems(prevItems => {
      const newItems = [ ...prevItems ];
      const index = prevItems.findIndex(i => i.id === item.id);
      if (index === -1) {
        return newItems;
      }
      newItems[index] = typeof item === 'function' ? item(newItems[index]) : item;
      return newItems;
    })
  }

  return {
    filters,
    items,
    refresh: () => load(true),
    isLoading,
    setItem,
    load,
    page
  }
}
