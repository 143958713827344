import React, { useEffect, useState } from 'react';
import Api from '../../services/Api';
import './etipi.scss';
import { useAuth } from '../../hooks/auth/Auth';
import { Input, useForm } from '../../hooks/form';
import { Loader } from '../../components/loader';

import { Elements, useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { setCookie } from '../../hooks/auth/session';

const CheckoutForm = ({ onPaid, checkAddress, price }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    if (!checkAddress()) {
      setIsLoading(false);
      return;
    }

    Api.post(`/generate-payment-intent-donation`, {
      price,
    }).then(({ data }) => {
      const cardElement = elements.getElement(CardNumberElement);
      stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: cardElement
        }
      }).then(function(result) {
        if (result.error) {
          setError(result.error.message);
          // Show error to your customer (e.g., insufficient funds)
          console.log(result.error);
          setIsLoading(false);
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            setIsLoading(false);
            onPaid(result.paymentIntent);
          }
        }
      });
    }).catch(() => {
      alert('Une erreur est survenue');
      setIsLoading(false);
    });
  };

  return (
    <form className="form paiment" onSubmit={handleSubmit}>
      <label htmlFor="lastname">Numéro de carte *</label>
      <div className="input"><CardNumberElement /></div>
      <div className="box" style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <label htmlFor="country">Date d'expiration *</label>
          <div className="input"><CardExpiryElement /></div>
        </div>
        <div style={{ flex: 1, marginLeft: '20px' }}>
          <label htmlFor="phone">CVC *</label>
          <div className="input"><CardCvcElement /></div>
        </div>
      </div>
      { error &&
        <div style={{ padding: '5px', color: 'red', marginTop: '-10px' }}>
          {error}
        </div>
      }

      <div className="ctas">      
        <button className="btn" type="submit" disabled={isLoading || !stripe}>
          Faire un don par carte bancaire
        </button>
      </div>

      <br/>
      <br/>
      *champs obligatoires
      <br />
      **Les reçus fiscaux, pour un don par carte ou par chèque, sont envoyés par e-mail, au plus tard au mois de mars suivant l'année de votre don. Ceci afin de vous rappeler de faire valoir votre déduction fiscale sur votre déclaration de revenus dès le mois d'avril. A noter que, pour les dons par chèques, seuls les chèques reçus avant le 22 déc. 2022 pourront faire l’objet d’un reçu fiscal pour l’année 2022.
      <br /><br />
      Vous pouvez nous adresser un chèque à l’ordre de Ecole Cathédrale à l’adresse suivante :
      <br />
      Collège des Bernardins<br />
      Formation e-learning<br />
      20 rue de Poissy<br />
      75005 Paris<br />
    </form>
  );
};

export default function Donate ({ route }) {
  const auth = useAuth();
  const [price, setPrice] = useState(100);
  const [amounts, setAmounts] = useState([]);
  const [error, setError] = useState();
  const form = useForm({
    address: '',
    zipcode: '',
    city: '',
    country: '',
    phoneNumber: '',
  });
  const [isLoadingRegistration, setIsLoadingRegistration] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      // Redirect to sso if not authenticated
      setCookie('auth_redirect', window.location.pathname);
      window.location.href = 'https://account.collegedesbernardins.fr/login?client_id=32379462d2ca4b7eb50e43839b090c86&redirect_uri=https://alpha.collegedesbernardins.fr/auth'
    } else {
      // Retrieve address if exists
      Api.get('/me', { address: true })
        .then((response) => {
          if (response.data.address) {
            form.setValue('address', response.data.address.address);
            form.setValue('zipcode', response.data.address.zipcode);
            form.setValue('city', response.data.address.city);
            form.setValue('country', response.data.address.country);
            form.setValue('phoneNumber', response.data.address.phoneNumber);
          }
        });

      
      Api.get(`/amount-donations`)
      .then((response) => {
        setAmounts(response.data);
        setPrice(response.data[0].amount);
      }).catch(() => {
        alert('Une erreur est survenue');
      });
    }
  }, [route.params.slug]);

  function onPaid(paymentIntent) {
    setIsLoadingRegistration(true);

    Api.post('/save-donation', {
      price,
      billingAddress: form.data,
      paymentIntent,
    }).then(() => {
      setSuccess(true);
    }).catch(() => {
      alert('Une erreur est survenue, veuillez contacter le collège des bernardins.');
      setIsLoadingRegistration(false);
    })
  }

  function checkAddress() {
    // test form
    const err = {};

    if (form.data.address.length < 1) {
      err.address = 'Veuillez remplir le champs.';
    }
    if (form.data.zipcode.length < 1) {
      err.zipcode = 'Veuillez remplir le champs.';
    }
    if (form.data.city.length < 1) {
      err.city = 'Veuillez remplir le champs.';
    }
    if (form.data.country.length < 1) {
      err.country = 'Veuillez remplir le champs.';
    }
    if (form.data.phoneNumber.length < 1) {
      err.phoneNumber = 'Veuillez remplir le champs.';
    }

    if (Object.keys(err).length > 0) {
      form.setErrors(err);
      return false;
    } else {
      form.setErrors({});
    }
    return true;
  }

  if (!auth.isAuthenticated) {
    return (
      <div className="etipi" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </div>
    );
  }

  if (success) {
    return (
      <div className="etipi">
        <div className="header">
          <img className="logo" src="/new-logo.png" alt=""/>
          <div className="identity">
            { auth.isAuthenticated && auth.user &&
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br />
                {auth.user.email}
                <br />
                <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            }
          </div>
        </div>

        <div className="bar"></div>

        <div className="page">

          <div style={{ padding: '50px 0px', textAlign: 'center' }}>
            <div style={{ marginBottom: '10px', fontSize: '1.6em', color: '#d29e3a' }}>Votre don a été validé</div>
            <div style={{ marginBottom: '20px', color: 'grey' }}>Vous allez recevoir un message de confirmation à l’adresse mail de votre compte.</div>
            <br />
            <br />
          </div>
        </div>
      </div>
    )
  }

  if (isLoadingRegistration) {
    return (
      <div className="etipi">
        <div className="header">
          <img className="logo" src="/new-logo.png" alt=""/>
          <div className="identity">
            { auth.isAuthenticated && auth.user &&
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br />
                {auth.user.email}
                <br />
                <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            }
          </div>
        </div>

        <div className="bar"></div>

        <div className="page">

          <div style={{ padding: '50px 0px', textAlign: 'center' }}>

            <div style={{ padding: '50px 0px', textAlign: 'center' }}>
              <div style={{ marginBottom: '10px', fontSize: '1.6em' }}>Votre don est en cours d'enregistrement ..</div>
              <div style={{ marginBottom: '20px', color: 'grey' }}>Cela peut prendre quelque secondes.</div>
              <Loader />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="etipi">
      <div className="header">
        <a href="https://www.lecampusdesbernardins.fr/">
          <img className="logo" src="/new-logo.png" alt=""/>
        </a>
        <div className="identity">
          { auth.isAuthenticated && auth.user &&
            <>
              {auth.user.firstname} {auth.user.lastname}
              <br />
              {auth.user.email}
              <br />
              <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
            </>
          }
        </div>
      </div>

      <div className="bar"></div>

      <div className="page">
        {/* { !notFound && !classe &&
          <div style={{ padding: '50px 0px' }}>
            <Loader />
          </div>
        } */}
        { true &&
          <>
            <h3>Oui, je souhaite faire grandir la formation pour tous les chercheurs de Dieu !</h3>
            <div className="panel">
              <div className="classe">
                <div className="infos">
                  {/* <div className="title">
                    Le Campus des Bernardins, c’est l’ambition de proposer une formation de qualité à tous ceux qui sont en quête de sens. Nous soutenir, c’est participer à l’aventure d’une plateforme de formation à distance qui souhaite s’ouvrir au plus grand nombre. Merci !
                  </div> */}
                  <strong>Avec le reçu fiscal**, votre don de 40 € vous coûte 13,6 € après réduction d'impôts de 66%</strong>
                </div>
              </div>
            </div>

            {/* <h3>
              <div className="bullet">1</div>
              Je souhaite envoyer un chèque
            </h3>
            <div className="panel">
              <div className="classe">
                <div className="infos">
                  <div className="title">
                    Vous pouvez nous adresser un chèque à l’ordre de Ecole Cathédrale à l’adresse suivante :
                  </div>
                  <br />
                  Collège des Bernardins<br />
                  Formation e-learning<br />
                  20 rue de Poissy<br />
                  75005 Paris<br />
                  <br />
                  En cas de don par chèque, inutile de renseigner les sections suivantes qui sont destinées aux dons par carte bancaire.
                </div>
              </div>
            </div> */}

              
            <h3>
              <div className="bullet">1</div>
              Je souhaite donner par carte bancaire. Veuillez choisir votre montant 
            </h3>
            <div className="tarifs">
              <div className="plans">
                {
                  amounts.map(amount => (
                    <div key={amount.id} className={`plan${price === amount.amount ? ' active' : ''}`} onClick={() => setPrice(amount.amount)}>
                      {amount.amount}€
                    </div>
                  ))
                }
                <div className={`plan${amounts.findIndex(a => a.amount === price) === -1 ? ' active' : ''}`} onClick={() => setPrice(50)}>
                  Autre montant
                </div>
              </div>
            </div>
            { amounts.findIndex(a => a.amount === price) === -1 && 
              <form>
                <Input label="Montant souhaité" value={price} onChange={event => setPrice(event.target.value)} type="number" step="1" />
              </form>
            }
            
            <form className="infos">
              <h3><div className="bullet">
                2
              </div>Informations pour recevoir votre reçu fiscal :</h3>
              { error &&
                <div style={{ padding: '5px 0px', color: 'red', marginTop: '-10px' }}>
                  {error}
                </div>
              }
              {/* { form.data.address !== null && form.data.address !== '' && openAddress === false &&
                <div className="address">
                  <div>
                    {form.data.address}
                    <br />
                    {form.data.zipcode} {form.data.city}
                    <br />
                    {form.data.country}
                    <br />
                    {form.data.phoneNumber}
                  </div>
                  <div className="update" onClick={() => setOpenAddress(true)}>Modifier</div>
                </div>
              } */}
              {/* { (openAddress || form.data.address === null || form.data.address === '') &&
                <> */}
                  <Input label="Adresse *" {...form.field('address')} />
                  <br />
                  <div className="form-divid">
                    <div style={{ flex: 1 }}>
                      <Input label="Code postal *" {...form.field('zipcode')} />
                    </div>
                    <div className="separator" />
                    <div style={{ flex: 1 }}>
                      <Input label="Ville *" {...form.field('city')} />
                    </div>
                  </div>
                  <br />
                  <div className="form-divid">
                    <div style={{ flex: 1 }}>
                      <Input label="Pays *" {...form.field('country')} />
                    </div>
                    <div className="separator" />
                    <div style={{ flex: 1 }}>
                      <Input label="Téléphone *" {...form.field('phoneNumber')} />
                    </div>
                  </div>
                {/* </>
              } */}
            </form>
            <h3><div className="bullet">3</div>Paiement :</h3>
            <CheckoutForm onPaid={(paymentIntent) => onPaid(paymentIntent)} price={price} checkAddress={() => checkAddress()} />
          </>
        }
      </div>
    </div>
  );
};
