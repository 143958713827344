import React, { useEffect, useState } from 'react';

import { Head } from '../../components/head';
import { Input, Select } from '../../hooks/form';
import { useList, List } from '../../hooks/list';
import { useModal } from '../../hooks/modal/Modal';

export default function AddProductModal({ onAdded, many, excludeIds, price = 24 }) {
  const modal = useModal();
  const { filters, items, isLoading, load } = useList('products', {
    search: '',
    //channel: 'distanciel',
    price,
    type: 'audio',
    excludeIds,
  });
  const [selectedProducts, setSelectedProducts] = useState([]);

  function select(product) {
    setSelectedProducts(prevProducts => [...prevProducts, product]);
  }

  useEffect(() => {
    // console.log(many, selectedProducts.length)
    if ((many === 'two' && selectedProducts.length === 1)  || (many === 'three' && selectedProducts.length === 2)) {
      onAdded(selectedProducts);
      modal.close();
    }
  }, [selectedProducts]);

  return (
    <div className="list-page" style={{ width: '100%', overflow: 'hidden' }}>
      <h1 style={{ background: 'black', color: 'white', margin: '0px', padding: '20px', borderRadius: '8px 8px 0px 0px' }}>Choisissez un produit</h1>
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        {/* <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('period')}>
            <option value={null}>Toutes périodes</option>
            <option value="annual">Annuel</option>
            <option value="first-semester">1er semestre</option>
            <option value="second-semester">2nd semestre</option>
          </Select>
        </div> */}
      </form>
      <List scrollableClassName=".component-modal" onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'name' }, { name: 'Séances' }]}>
        { items.filter(product => product.individualPriceTtc !== null && product.individualPriceTtc !== 0).map(product => (
          <div key={product.id} className={`item${selectedProducts.findIndex(sProduct => sProduct.id === product.id) !== -1 ? ' selected' : ''}`} onClick={() => select(product)} >
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              {/* <div style={{ marginRight: '10px', borderRadius: '10px', background: 'green', width: '10px', height: '10px', flex: '0 0 auto' }}></div> */}
              <span>
                {product.number} • {product.name}
              </span>
            </div>
            <div className="prop">
              {product.period ? { annual: 'Annuel', 'first-semester': '1er semestre', 'second-semester':'2nd semestre'}[product.period] : ''} {product.period && product.year && <>•</>} {product.year ? `${product.year} - ${product.year+1}` : ''}
            </div>
          </div>
        ))}
      </List>
    </div>
  )
}