import { useState, useEffect, useCallback } from "react";
import { useRef } from "react";

function generateId(length = 10) {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export default function useUpload({ onUpload, defaultValue = null, types = null } = {}) {
  const id = useRef(generateId());
  const [file, setFile] = useState(defaultValue);
  const [errors, setErrors] = useState([]);
  const params = useRef({});
  const onUploadRef = useRef(onUpload);

  // useEffect(() => {
  //   setFile(defaultValue);
  // }, [defaultValue]);

  useEffect(() => {
    const element = document.createElement('input');
    element.id = `upload-file-input-${id.current}`;
    element.type = 'file';
    element.style = 'display: none;';
    element.onchange = (event) => {
      const file = event.target.files[0];
      document.querySelector(`#upload-file-input-${id.current}`).value = '';

      if (types !== null && types.indexOf(file.type) === -1) {
        alert('Please use an image (jpg or png)');
        return;
      }
      if (onUploadRef.current) onUploadRef.current(file, params.current);

      setFile(file);

      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   var image = new Image();
      //   image.src = e.target.result;
      //   image.onload = () => {
      //     const newFile = {
      //       name: file.name,
      //       size: file.size,
      //       type: file.type,
      //       data: e.target.result,
      //       width: image.width,
      //       height: image.height,
      //     };
      //     setFile(newFile);
      //     if (onUploadRef.current) onUploadRef.current(file, newFile, params.current);
      //     document.querySelector(`#upload-file-input-${id.current}`).value = '';
      //   };
      // };
      // reader.readAsDataURL(file);
    };
    document.body.appendChild(element);

    return () => {
      document.querySelector(`#upload-file-input-${id.current}`).remove();
    }
  }, []);

  function start(event, startParams = {}) {
    if (event && event.target) {
      event.preventDefault();
      event.stopPropagation();
      params.current = startParams;
    } else {
      params.current = event || {};
    }
    document.querySelector(`#upload-file-input-${id.current}`).click();
  }

  function reset(event) {
    if (event && event.target) {
      event.preventDefault();
      event.stopPropagation();
    }
    setFile(null);
  }

  return {
    start,
    file,
    errors,
    reset,
    onUpload: cb => onUploadRef.current = cb
  };
};
