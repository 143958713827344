import React from 'react';
import { useAuth } from '../auth/Auth';
import { setCookie } from '../auth/session';
import { useRouter } from './Router';

export default function Route({ deep, notAuthorized, route, layout, ...props }) {
  const auth = useAuth();
  const router = useRouter();

  let renderedRoute = route;
  if (renderedRoute === undefined) {
    const tempRoute = router.route;
    if (tempRoute === false) {
      // If false, router didn't has time to run componentDidMount
      // Waiting for it
      return null;
    }
    renderedRoute = tempRoute;
    Array.from(Array(deep || 0)).forEach(() => {
      renderedRoute = renderedRoute.subRoute
    });
  }

  if (renderedRoute) {
    if (
      renderedRoute.acl !== 'guest'
      // Only check "isAuthenticated", it is a synchronous state, user infos will be loaded after (API call) => faster rendering
      && !auth.isAuthenticated
    ) {
      // Redirect to sso if not authenticated
      let redirect =  window.location.pathname;
      if (router.route.query !== undefined && router.route.query.redirect !== undefined && router.route.query.redirect !== '') {
        redirect = router.route.query.redirect;
      }
      setCookie('auth_redirect', redirect);
      window.location.href = 'https://account.collegedesbernardins.fr/logout?client_id=32379462d2ca4b7eb50e43839b090c86&redirect_uri=https://alpha.collegedesbernardins.fr/auth'
    } else {
      const RouteComponent = renderedRoute.component;
      const Layout = layout;
      if (Layout && renderedRoute.layout !== false) {
        return (
          <Layout route={renderedRoute}>
            <RouteComponent {...props} route={renderedRoute} />
          </Layout>
        );
      } else {
        return (
            <RouteComponent {...props} route={renderedRoute} />
        );
      }
    }
  } else {
    if (props.notFound) {
      const Component = props.notFound;
      return <Component />
    } else {
      return (
        <div>NOT FOUND</div>
      );
    }
  }
};
