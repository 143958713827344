import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Input, Select, Radio, useForm } from '../../hooks/form';
import { Link } from '../../hooks/router';
import Api from '../../services/Api';
import './pay.scss';

import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { Loader } from '../../components/loader';

export default function Pay ({ route }) {
  const stripe = useStripe();
  const [payment, setPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const clientSecret = useRef(null);


  useEffect(() => {
    Api.get(`/payments/${route.params.paymentIdentifier}/start`)
      .then(response => {
        if (response.data.error) {
          setError(response.data.error === 'already_paid' ? 'Le paiment a déjà été fait.' : 'Une erreur est survenue'); 
        } else {
          setPayment(response.data.payment);
          clientSecret.current = response.data.clientSecret;
        }
      })
      .catch(error => {
        alert('Une erreur est survenue');
      })
  }, [route])

  const [success, setSuccess] = useState(false);
  const elements = useElements();

  const handleSubmit = async (event) => {
    console.log('KEKEKEKE')
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardNumberElement);
    stripe.confirmCardPayment(clientSecret.current, {
      payment_method: {
        card: cardElement
      }
    }).then(function(result) {
      if (result.error) {
        // Show error to your customer
        setPaymentError(result.error.message);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          Api.post(`/payments/${route.params.paymentIdentifier}/pay`, {
            paymentId: result.paymentIntent.id
          }).then(() => {
            setSuccess(true);
          }).catch(() => alert('Une erreur est survenue'));
        }
      }
    }).catch((error) => {
      console.log(error);
      alert('Une erreur est survenue');
      setIsLoading(false);
    });
  };

  if (error) {
    return (
      <div className="pay-page">
        <div className="wrapper">
          <div style={{ padding: '50px 0px', textAlign: 'center' }}>
            <div style={{ marginBottom: '10px', fontSize: '1.6em', color: 'red' }}>Vous ne pouvez pas procéder au paiement</div>
            <div style={{ marginBottom: '20px', color: 'red' }}>{error}</div>
          </div>
        </div>
      </div>
    )
  }

  if (payment === null) {
    return (
      <div className="pay-page">
        <div className="wrapper">
          <Loader />
        </div>
      </div>
    )
  }

  if (success) {
    return (
      <div className="pay-page">
        <div className="wrapper">
          <div style={{ padding: '50px 0px', textAlign: 'center' }}>
            <div style={{ marginBottom: '10px', fontSize: '1.6em', color: 'green' }}>Votre paiement a été validé</div>
            <br/>
            <div>
              Si vous souhaitez consulter votre compte étudiant, cliquez sur le bouton ci-dessous
            </div>
            <br/>
            <Link className="btn" to="classes" target>Aller sur votre compte</Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="pay-page">
        <div className="wrapper">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/images/SVG/logo-menu.svg" alt="logo" style={{ width: '20px', marginRight: '20px' }} />
          <div className="message" style={{ flex: 1 }}>
            Bienvenue sur la plateforme de paiement du Collège des bernardins !
          </div>
        </div>

        <h3 style={{ textAlign: 'center', fontSize: '1.4em' }}>
          <span style={{ color: '' }}>Facture #{payment.bill.number}</span>
          <br />
          Paiement de {payment.amount}€
        </h3>
        Veuillez renseignez vos informations de paiement : 
        <br /><br />
        <form className="form" onSubmit={handleSubmit}>
          <label htmlFor="lastname">Numéro de carte *</label>
          <div className="input"><CardNumberElement /></div>
          <div className="box">
            <div>
              <label htmlFor="country">Date d'expiration *</label>
              <div className="input"><CardExpiryElement /></div>
            </div>
            <div>
              <label htmlFor="phone">CVC *</label>
              <div className="input"><CardCvcElement /></div>
            </div>
          </div>
          { paymentError &&
            <div style={{ padding: '5px', color: 'red', marginTop: '-10px' }}>
              {paymentError}
            </div>
          }

          <div className="ctas">
            <button className="btn" type="submit" disabled={!payment || !stripe || isLoading}>
              Payer par carte bancaire
            </button>
          </div>
        </form>
      </div>

      <div style={{ color: 'grey', margin: '10px 0px', textAlign: 'center' }}>* Champs obligatoires</div>
    </div>
  );
};
