import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import routes from './config/route';

import { ProvideRouter }  from './hooks/router/Router';
import Route from './hooks/router/Route';
import { ProvideAuth } from './hooks/auth/Auth';
import { ProvideApp } from './hooks/app/App';
import { ProvideModal } from './hooks/modal/Modal';
import { ProvideNotification } from './hooks/notification/Notification';

import Login from './pages/login/Login';
import ErrorPage from './pages/errorPage/ErrorPage';
import UserLayout from './layouts/UserLayout';

const stripePromise = loadStripe('pk_live_51IvJwYKLe0dA0qdSIXHEaRmDYuOwYP0qHB4Jubxt860IbJat1JkFS8iJzsR97nBO8TmwZdVT2fHrc8mcg6SlPz8l00wjBqEhfs');
// const stripePromise = loadStripe('pk_test_51IvJwYKLe0dA0qdSL1KPLMXIlW7PdpsZ86xARUNuxdynFj1JfAXiidytS4vCwLKsdTlo9XwCknKPdf8jkzpKW06x00RVMu9AO6');

export default function App () {
  return (
    <ProvideApp reducer={(state, { action, params }) => {
      switch (action) {
        case 'hideMenu':
          return { hideMenu: true };
        case 'showMenu':
          return { hideMenu: false };
        case 'toggleMenu':
          return { hideMenu: !state.hideMenu };
        default:
          throw new Error(`[AppContext] Action ${action} is not valid`);
      }
    }} initialArg={{ hideMenu: false }}>
      <ProvideAuth>
        <ProvideRouter routes={routes}>
          <ProvideNotification>
            <ProvideModal>
              <Elements stripe={stripePromise}>
                <Route notFound={ErrorPage} layout={UserLayout} />
              </Elements>
            </ProvideModal>
          </ProvideNotification>
        </ProvideRouter>
      </ProvideAuth>
    </ProvideApp>
  );
};
