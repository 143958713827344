import React, { useState, useEffect, useContext, createContext } from "react";

import { setCookie, removeCookie, getCookie } from './session';
import Api from "../../services/Api";

const CookieTokenName = 'xTokBernardinsStudentV2';

const authContext = createContext();
authContext.displayName = 'Auth';

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(getCookie(CookieTokenName));
  const [isAuthenticated, setIsAuthenticated] = useState(getCookie(CookieTokenName) !== null);

  useEffect(() => {
    if (getCookie(CookieTokenName)) {
      setIsAuthenticated(true);
      Api.get('/me').then(({ data }) => {
        setUser(data);
      });
    }
  }, []);
  
  const signin = (email, password) => {
    return Api.post('/authenticate', { email, password }, undefined, true)
      .then(({ data }) => {
        setUser(data.user);
        setToken(data.token);
        setCookie(CookieTokenName, data.token);
        if (!isAuthenticated) {
          setIsAuthenticated(true);
        }

        // Send to parent when app is in iframe
        if (window.opener) window.opener.postMessage({ connected: true, user }, "*");
        return data.user;
      });
  };

  const forceLogin = (token, user) => {
    setUser(user);
    setToken(token);
    setCookie(CookieTokenName, token);
    if (!isAuthenticated) {
      setIsAuthenticated(true);
    }
    if (window.opener) window.opener.postMessage({ connected: true, user }, "*");
  }

  const signup = (email, password) => {
    return Api.post('/register', { email, password }, undefined, true)
      .then(({ data }) => {
        setUser(data.user);
        setToken(data.token);
        setCookie(CookieTokenName, data.token);
        if (!isAuthenticated) {
          setIsAuthenticated(true);
        }
      });
  };

  const signout = () => {
    removeCookie(CookieTokenName);
    setUser(null);
    setToken(null);
    setIsAuthenticated(false);

    // Reset cart
    localStorage.setItem('bernCartClasses', JSON.stringify([]));

    window.location.href = 'https://account.collegedesbernardins.fr/logout?post_logout_redirect_uri=https://alpha.collegedesbernardins.fr/auth'
  };

  const forgotPassword = email => {
    return Api.post('/forgot-password', { email });
  };

  const resetPassword = (code, password) => {
    return Api.post('/reset-password', { code, password });
  };
  
  
  return {
    token,
    user,
    isAuthenticated,
    signin,
    signup,
    signout,
    forgotPassword,
    resetPassword,
    forceLogin,
    setUser
  };
};
