import Dashboard from "../pages/Dashboard";

import Account from "../pages/account/Account";
import AccountEmail from "../pages/account/AccountEmail";
import AccountPassword from "../pages/account/AccountPassword";
import ForgotPassword from "../pages/forgotPassword/ForgotPassword";
import ResetPassword from "../pages/resetPassword/ResetPassword";

import ClassesList from "../pages/classes/List";
import ClasseRessource from "../pages/classes/Ressource";
import ClasseRessourceOverview from "../pages/classes/ressource/Overview";
import ClasseRessourcePlanning from "../pages/classes/ressource/Planning";

import RegistrationsList from "../pages/registrations/List";
import RegistrationRessource from "../pages/registrations/Ressource";
import RegistrationRessourceOverview from "../pages/registrations/ressource/Overview";

import BillsList from "../pages/bills/List";
import Register from "../pages/register/Register";
import Activate from "../pages/activate/Activate";

import IFrame from "../pages/iframe/IFrame";
import IFrameList from "../pages/iframelist/IFrameList";
import IFrameYearList from "../pages/iframeyearlist/IFrameYearList";
import IFrameCCDEJ from "../pages/iframeccdej/IFrameCCDEJ";
import IFrameCCDEJYear from "../pages/iframeccdejyear/IFrameCCDEJYear";
import Checkout from "../pages/checkout/Checkout";
import Pay from "../pages/pay/Pay";
import Sinod from "../pages/sinod/Sinod";
import Etipi from "../pages/etipi/Etipi";
import Certificate from "../pages/etipi/Certificate";
import Donate from "../pages/etipi/Donate";
import Auth from "../pages/auth";
import Audio from "../pages/etipi/Audio";

export default [
  // {
  //   name: 'dashboard',
  //   url: '/',
  //   component: Dashboard,
  //   acl: 'user',
  // },
  // {
  //   name: 'bills',
  //   url: '/bills',
  //   component: BillsList,
  //   acl: 'user',
  // },
  // {
  //   name: 'classes',
  //   url: '/classes',
  //   component: ClassesList,
  //   acl: 'user',
  // },
  // {
  //   name: 'classe',
  //   url: '/classe/:id',
  //   component: ClasseRessource,
  //   acl: 'user',
  //   children: [
  //     {
  //       name: '_default',
  //       url: '',
  //       component: ClasseRessourceOverview,
  //     },
  //     {
  //       name: 'planning',
  //       url: '/planning',
  //       component: ClasseRessourcePlanning,
  //     },
  //   ]
  // },
  // {
  //   name: 'registrations',
  //   url: '/registrations',
  //   component: RegistrationsList,
  //   acl: 'user',
  // },
  // {
  //   name: 'registration',
  //   url: '/registration/:id',
  //   component: RegistrationRessource,
  //   acl: 'user',
  //   children: [
  //     {
  //       name: '_default',
  //       url: '',
  //       component: RegistrationRessourceOverview,
  //     },
  //   ]
  // },
  // {
  //   name: 'account',
  //   url: '/account',
  //   component: Account,
  //   acl: 'user',
  //   children: [
  //     {
  //       name: '_default',
  //       url: '',
  //       component: AccountEmail,
  //     },
  //     {
  //       name: 'password',
  //       url: '/password',
  //       component: AccountPassword,
  //     }
  //   ]
  // },

  // Guest pages
  {
    name: 'auth',
    url: '/auth',
    component: Auth,
    acl: 'guest',
    layout: false,
  },
  {
    name: 'register',
    url: '/register',
    component: Register,
    acl: 'guest',
    layout: false,
  },
  // {
  //   name: 'activate',
  //   url: '/activate/:userId',
  //   component: Activate,
  //   acl: 'guest',
  //   layout: false,
  // },
  // {
  //   name: 'forgot-password',
  //   url: '/forgot-password',
  //   component: ForgotPassword,
  //   acl: 'guest',
  //   layout: false,
  // },
  // {
  //   name: 'reset-password',
  //   url: '/reset-password/:token',
  //   component: ResetPassword,
  //   acl: 'guest',
  //   layout: false,
  // },

  // Sinod
  // {
  //   name: 'sinod-classe',
  //   url: '/sinod/classe/:slug',
  //   component: Sinod,
  //   acl: 'guest',
  //   layout: false,
  // },

  // Etipi
  {
    name: 'etipi-classe',
    url: '/campus/classe/:slug',
    component: Etipi,
    acl: 'guest',
    layout: false,
  },
  {
    name: 'etipi-donate',
    url: '/campus/donate',
    component: Donate,
    acl: 'guest',
    layout: false,
  },
  {
    name: 'etipi-certificate',
    url: '/campus/certificate/:slug',
    component: Certificate,
    acl: 'guest',
    layout: false,
  },
  {
    name: 'etipi-audio',
    url: '/campus/audio/:slug',
    component: Audio,
    acl: 'guest',
    layout: false,
  },

  // Public
  {
    name: 'iframe-classe',
    url: '/iframe/classe/:slug',
    component: IFrame,
    acl: 'guest',
    layout: false,
  },
  {
    name: 'checkout',
    url: '/checkout',
    component: Checkout,
    acl: 'guest',
    layout: false,
  },
  {
    name: 'iframe-ccdej',
    url: '/iframe/ccdej',
    component: IFrameCCDEJ,
    acl: 'guest',
    layout: false,
  },
  {
    name: 'iframe-ccdej-year',
    url: '/iframe/ccdej/:year',
    component: IFrameCCDEJYear,
    acl: 'guest',
    layout: false,
  },
  {
    name: 'iframe',
    url: '/iframe/list/:semester',
    component: IFrameList,
    acl: 'guest',
    layout: false,
  },
  {
    name: 'iframe-year',
    url: '/iframe/classes/:slug',
    component: IFrameYearList,
    acl: 'guest',
    layout: false,
  },
  {
    name: 'pay',
    url: '/pay/:paymentIdentifier',
    component: Pay,
    acl: 'guest',
    layout: false,
  },
];