import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Input, Select, Radio, useForm } from '../../hooks/form';
import { Link } from '../../hooks/router';
import Api from '../../services/Api';
import './checkout.scss';

import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement, IbanElement } from '@stripe/react-stripe-js';
import { Loader } from '../../components/loader';
import { useAuth } from '../../hooks/auth/Auth';
import { getCookie, setCookie } from '../../hooks/auth/session';

const enToFr = {
  "January": "janvier",
  "February": "février",
  "March": "mars",
  "April": "avril",
  "May": "mai",
  "June": "juin",
  "July": "juillet",
  "August": "août",
  "September": "septembre",
  "October": "octobre",
  "November": "novembre",
  "December": "décembre"
}

const CheckoutForm = ({ onPaid, classes, options, tarifTypes, checkAddress, packages }) => {
  const auth = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [threeTime, setThreeTime] = useState(false);
  const ibanForm = useForm({
    lastname: '',
    email: '',
  })

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    if (!checkAddress()) {
      setIsLoading(false);
      return;
    }

    let stripeElement = null;
    if (threeTime === true) {
      stripeElement = elements.getElement(IbanElement);

      const err = {};

      if (ibanForm.data.lastname.length < 1) {
        err.lastname = 'Veuillez remplir le champs.';
      }
      if (ibanForm.data.email.length < 1) {
        err.email = 'Veuillez remplir le champs.';
      }
      
      if (Object.keys(err).length > 0) {
        console.log('KEKEKEK', err);
        setIsLoading(false);
        ibanForm.setErrors(err);
        return false;
      }

    } else {
      stripeElement = elements.getElement(CardNumberElement);
    }
    Api.post(`/generate-setup-intent`, {
      classes,
      options,
      tarifTypes,
      threeTime,
      packages,
    }).then(({ data }) => {
      if (threeTime) {
        console.log('CONFIRM SETUP IBAN')
        stripe.confirmSepaDebitSetup(
          data.clientSecret,
          {
            payment_method: {
              sepa_debit: stripeElement,
              billing_details: {
                name: ibanForm.data.lastname,
                email: ibanForm.data.email,
              },
            },
          }
        ).then(function(result) {
          console.log('CONFIRM SETUP IBAN SUCCESS', result)
          if (result.error) {
            setError(result.error.message);
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error);
            setIsLoading(false);
          } else {
            // The payment has been processed!
            // if (result.setupIntent.status === 'succeeded') {
              setIsLoading(false);
              onPaid(result.setupIntent, threeTime);
            // }
          }
        });
      } else {
        stripe.confirmCardPayment(data.clientSecret, {
          payment_method: {
            card: stripeElement,
          },
        }).then(function(result) {
          if (result.error) {
            setError(result.error.message);
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error);
            setIsLoading(false);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              setIsLoading(false);
              onPaid(result.paymentIntent, threeTime);
            }
          }
        });
      }
      
    }).catch((error) => {
      console.log(error);
      alert('Une erreur est survenue');
      setIsLoading(false);
    });
  };

  const sepPrice = options.reduce((price, option) => price + option.price, classes.reduce((amount, classe) => amount + classe[tarifTypes.find(tt => tt.classeId === classe.id) ? tarifTypes.find(tt => tt.classeId === classe.id).tarifType : 'individualPriceTtc'], 0));

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="threetime" style={{ display: 'flex', marginBottom: '20px' }}>
        <div style={{ flex: 1, marginRight: '20px' }}>
          <Radio label="Paiement comptant" checkedValue={false} value={threeTime} onChange={event => setThreeTime(false)} />
        </div>
        <div style={{ flex: 1 }}>
          {/* <Radio label={<>
            Paiement en 3 fois
            <div style={{ display: 'flex' }}>
              <div>
                Prélèvements: 
              </div>
              <div style={{ marginLeft: '5px' }}>
                {(sepPrice - (sepPrice/3).toFixed(2) - (sepPrice/3).toFixed(2)).toFixed(2)}€ le {moment().format('DD')}  {enToFr[moment().format('MMMM')]} {moment().format('YYYY')}
                <br />{(sepPrice/3).toFixed(2)}€ le {moment().add(1, 'month').format('DD')}  {enToFr[moment().add(1, 'month').format('MMMM')]} {moment().add(1, 'month').format('YYYY')}
                <br />{(sepPrice/3).toFixed(2)}€ le {moment().add(2, 'month').format('DD')}  {enToFr[moment().add(2, 'month').format('MMMM')]} {moment().add(2, 'month').format('YYYY')}
              </div>
            </div>
          </>} checkedValue={true} value={threeTime} onChange={event => setThreeTime(true)} /> */}
        </div>
      </div>
                
      { threeTime ? (
        <>
          <div className="box" style={{ display: 'flex', gap: '20px', marginBottom: '10px' }}>
            <div style={{ flex: 1 }}>
              <Input label="Nom *" {...ibanForm.field('lastname')} />
            </div>
            <div style={{ flex: 1 }}>
              <Input label="Email *" {...ibanForm.field('email')} />
            </div>
          </div>
          <label htmlFor="lastname">IBAN *</label>
          <div className="input" style={{ marginBottom: '20px' }}>
            <IbanElement options={{ supportedCountries: ['SEPA'] }} />
          </div>
        </>
      ) : (
        <>
          <label htmlFor="lastname">Numéro de carte *</label>
          <div className="input"><CardNumberElement /></div>
          <div className="box">
            <div>
              <label htmlFor="country">Date d'expiration *</label>
              <div className="input"><CardExpiryElement /></div>
            </div>
            <div>
              <label htmlFor="phone">CVC *</label>
              <div className="input"><CardCvcElement /></div>
            </div>
          </div>
        </>
      )}
      
      { error &&
        <div style={{ padding: '5px', color: 'red', marginTop: '-10px' }}>
          {error}
        </div>
      }

      <div className="ctas">      
        <button className="btn" type="submit" disabled={isLoading || !stripe || classes.filter(c => c.isFull).length > 0}>
          Payer par { threeTime ? 'prélèvement bancaire' : 'carte bancaire' }
        </button>
      </div>

      <br/>
      <br/>
      *champs obligatoires
    </form>
  );
};

export default function Checkout ({ route }) {
  const stripe = useStripe();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [classes, setClasses] = useState([]);
  const [options, setOptions] = useState([]);
  const [tarifTypes, setTarifTypes] = useState([]);
  const form = useForm({
    address: '',
    zipcode: '',
    city: '',
    country: '',
    phoneNumber: '',
  });
  const [packages, setPackages] = useState([]);
  const [isLoadingRegistration, setIsLoadingRegistration] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      // Redirect to sso if not authenticated
      setCookie('auth_redirect', window.location.pathname);
      window.location.href = 'https://account.collegedesbernardins.fr/login?client_id=32379462d2ca4b7eb50e43839b090c86&redirect_uri=https://alpha.collegedesbernardins.fr/auth'
    } else {
      let cart = localStorage.getItem('bernCartClasses');
      if (cart === null) {
        cart = [];
      } else {
        cart = JSON.parse(cart);
      }
      let pkgs = localStorage.getItem('bernCartPackages');
      if (pkgs === null) {
        pkgs = [];
      } else {
        pkgs = JSON.parse(pkgs);
      }

      // Retrieve address if exists
      Api.get('/me', { address: true })
        .then((response) => {
          if (response.data.address) {
            form.setValue('address', response.data.address.address);
            form.setValue('zipcode', response.data.address.zipcode);
            form.setValue('city', response.data.address.city);
            form.setValue('country', response.data.address.country);
            form.setValue('phoneNumber', response.data.address.phoneNumber);
          }
        });

      // Manage saved tarifTypes
      setTarifTypes(cart.map(classe => ({
        classeId: classe.id,
        tarifType: classe.tarifType || 'individualPriceTtc',
        package: classe.package || false,
      })));

      // Manage saved packages
      setPackages(pkgs.map(pkg => ({
        id: pkg.id,
      })));
      setTarifTypes(prevTT => [...prevTT, ...pkgs.map(pkg => ({
        classeId: pkg.id,
        tarifType: 'specific',
        price: 30,
      }))]);

      Promise.all(cart.map(classe => {
        return Api.get(`/classes/${classe.id}`)
          .then(response => {
            setClasses(prevClasses => [...prevClasses, response.data]);
            setOptions(prevOptions => [...prevOptions, ...response.data.options.filter(op => op.required)]);
          })
          .catch(error => {
            console.log(error);
            alert('Une erreur est survenue.');
          });
      })).then(() => {
        setIsLoading(false);
      })
    }
  }, []);

  function onClickOption(option) {
    if (option.required) {
      return;
    }
    setOptions(prevOptions => {
      if (prevOptions.findIndex(op => op.id === option.id) !== -1) {
        return prevOptions.filter(op => op.id !== option.id);
      } else {
        return [ ...prevOptions, option ];
      }
    });
  }

  function checkAddress() {
    // test form
    const err = {};

    if (form.data.address.length < 1) {
      err.address = 'Veuillez remplir le champs.';
    }
    if (form.data.zipcode.length < 1) {
      err.zipcode = 'Veuillez remplir le champs.';
    }
    if (form.data.city.length < 1) {
      err.city = 'Veuillez remplir le champs.';
    }
    if (form.data.country.length < 1) {
      err.country = 'Veuillez remplir le champs.';
    }
    if (form.data.phoneNumber.length < 1) {
      err.phoneNumber = 'Veuillez remplir le champs.';
    }
    
    if (Object.keys(err).length > 0) {
      form.setErrors(err);
      return false;
    }

    form.setErrors({});
    return true;
  }

  function onPaid(paymentIntent, threeTime) {
    console.log('ON PAID GOO')
    setIsLoadingRegistration(true);
    Api.post('/checkout', {
      classes,
      options,
      tarifTypes,
      billingAddress: form.data,
      paymentIntent,
      setupIntent: paymentIntent,
      threeTime,
      packages,
    }).then(() => {
      setSuccess(true);

      // Reset cart
      localStorage.setItem('bernCartClasses', JSON.stringify([]));
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.paymentIntent) {
        stripe.confirmCardPayment(error.response.data.paymentIntent.client_secret, {
          payment_method: error.response.data.paymentIntent.last_payment_error.payment_method.id
        }).then(function(result) {
          if (result.error) {
            // Show error to your customer
            console.log(result.error.message);
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              Api.post('/checkout', {
                classes,
                options,
                tarifTypes,
                billingAddress: form.data,
                paymentIntent: result.paymentIntent,
                threeTime,
                packages,
              }).then(() => {
                setSuccess(true);
          
                // Reset cart
                localStorage.setItem('bernCartClasses', JSON.stringify([]));
              }).catch(() => alert('Une erreur est survenue'));
            }
          }
        });
      } else {
        alert('Une erreur est survenue');
      }
    });
  }

  function removeFromCart(classe) {
    let cart = localStorage.getItem('bernCartClasses');
    if (cart === null) {
      cart = [];
    } else {
      cart = JSON.parse(cart);
    }
    cart = cart.filter(cl => cl.id !== classe.id);

    localStorage.setItem('bernCartClasses', JSON.stringify(cart));

    setClasses(prevClasses => {
      return prevClasses.filter(cl => cl.id !== classe.id);
    });
    setOptions(prevOptions => {
      return prevOptions.filter(opt => opt.classeId !== classe.id);
    });
  }

  // if (!auth.isAuthenticated) {
  //   return (
  //     <div className="checkout-page">
  //       <div style={{ padding: '50px 0px', textAlign: 'center' }}>
  //         Pour continuer votre achat, vous devez posséder un compte étudiant sur la plateforme numérique du Collège des bernardins.
  //         <br/><br/>

  //         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  //           <Link className="btn" to="dashboard" query={{ redirect: encodeURIComponent(`checkout`) }}>Se connecter à mon compte étudiant</Link>
  //           <div style={{ padding: '0px 20px' }}>ou</div>
  //           <Link className="btn" to="register" query={{ redirect: encodeURIComponent(`checkout`) }}>Créer un compte étudiant</Link>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  if (success) {
    return (
      <div className="checkout-page">
        <div style={{ padding: '50px 0px', textAlign: 'center' }}>
          <div style={{ marginBottom: '10px', fontSize: '1.6em', color: 'green' }}>Votre inscription a été validée</div>
          <div style={{ marginBottom: '20px', color: 'grey' }}>Vous allez recevoir un mail de confirmation d'inscription</div>
          <br />
          <div>
            Si vous souhaitez continuer vos achats, cliquez sur le bouton ci-dessous
          </div>
          <br/>
          <Link className="btn" to={getCookie('year-version') === 'true' ? 'iframe-year' : 'iframe'} params={getCookie('year-version') === 'true' ? ({ slug: 'first-2022-2023' }) : ({ semester: 'first' })} style={{ display: 'inline-block', marginBottom: '20px' }}>Retour à la liste des cours</Link>
          <br/><br/>
          <div>
            Si vous souhaitez consulter votre compte étudiant, cliquez sur le bouton ci-dessous
          </div>
          <br/>
          <Link className="btn" to="classes" target>Aller sur votre compte</Link>
        </div>
      </div>
    )
  }

  if (isLoadingRegistration) {
    return (
      <div className="checkout-page">
        <div style={{ padding: '50px 0px', textAlign: 'center' }}>
          <div style={{ marginBottom: '10px', fontSize: '1.6em' }}>Votre inscription est en cours ..</div>
          <div style={{ marginBottom: '20px', color: 'grey' }}>Cela peut prendre quelque secondes.</div>
          <Loader />
        </div>
      </div>
    )
  }

  if (isLoading === false && classes.length === 0) {
    return (
      <div className="checkout-page">
        <div style={{ padding: '50px 0px', textAlign: 'center' }}>
          <div style={{ marginBottom: '10px', fontSize: '1.6em' }}>Vous n'avez choisi aucun cours</div>
          <br/><br/>

          <Link style={{ maxWidth: '200px', margin: 'auto' }} to={getCookie('year-version') === 'true' ? 'iframe-year' : 'iframe'} params={getCookie('year-version') === 'true' ? ({ slug: 'first-2022-2023' }) : ({ semester: 'first' })} className="link">Continuer les achats</Link>
        </div>
      </div>
    )
  }

  return (
    <div className="checkout-page">
      <div style={{ display: 'flex', alignItems: 'center' }}>

        <div className="message" style={{ flex: 1 }}>
          Bienvenue sur la plateforme d'inscription au collège des bernardins !
        </div>
        <div>
          <Link to={getCookie('year-version') === 'true' ? 'iframe-year' : 'iframe'} params={getCookie('year-version') === 'true' ? ({ slug: 'first-2022-2023' }) : ({ semester: 'first' })} className="link">Continuer les achats</Link>
        </div>
      </div>

      <div className="page">
        <div className="content">
          <h3>Mon panier :</h3>
          { isLoading &&
            <Loader />
          }
          {
            classes.map(classe => (
              <div key={classe.id} className="classe">
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, alignItems: 'flex-start' }}>
                    <div className="name">{classe.number} - {classe.name}</div>
                    Premier cours le {moment(classe.firstClasseAt).format('DD/MM/YYYY')}
                  </div>
                  <div style={{ padding: '5px', fontWeight: 'bold' }}>
                    {classe[tarifTypes.find(tt => tt.classeId === classe.id) ? tarifTypes.find(tt => tt.classeId === classe.id).tarifType : 'individualPriceTtc']}€
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer', padding: '5px' }} onClick={() => removeFromCart(classe)}>
                      <svg style={{ width: '20px', height: '20px' }} version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
                    </div>
                  </div>
                </div>

                { !classe.isFull ? (
                  <>
                    <h3>Choix du tarif : </h3>
                    <Select
                      noSearch
                      value={tarifTypes.find(tt => tt.classeId === classe.id) ? tarifTypes.find(tt => tt.classeId === classe.id).tarifType : 'individualPriceTtc'}
                      onChange={(value) => {
                        // Save is local storage
                        let cart = localStorage.getItem('bernCartClasses');
                        if (cart === null) {
                          cart = [];
                        } else {
                          cart = JSON.parse(cart);
                        }
                        const index = cart.findIndex(cartClasse => cartClasse.id === classe.id);
                        if (index !== -1) {
                          cart[index].tarifType = value;
                        }

                        localStorage.setItem('bernCartClasses', JSON.stringify(cart));

                        setTarifTypes(prevTarifTypes => {
                          const index = prevTarifTypes.findIndex(tt => tt.classeId === classe.id);
                          if (index === -1) {
                            prevTarifTypes.push({
                              classeId: classe.id,
                              tarifType: value,
                              package: false,
                            });
                          } else {
                            prevTarifTypes[index].tarifType = value;
                          }

                          return [...prevTarifTypes];
                        });
                      }}
                    >
                      <option value="individualPriceTtc">Tarif individuel • {classe['individualPriceTtc']}€</option>
                      <option value="youthPriceTtc">Tarif jeune - 30 ans • {classe['youthPriceTtc']}€</option>
                    </Select>

                    { classe.distancielClasse &&
                      <>
                        <h3>Package avec cours sur le Campus :</h3>
                        <div className="packages">
                          { classe.distancielClasse &&
                            <div className="package" onClick={() => {
                              // Save is local storage
                              let pkgs = localStorage.getItem('bernCartPackages');
                              if (pkgs === null) {
                                pkgs = [];
                              } else {
                                pkgs = JSON.parse(pkgs);
                              }
                              const index = pkgs.findIndex(pkgsClasse => pkgsClasse.id === classe.distancielClasse.id);
                              if (index !== -1) {
                                pkgs = pkgs.filter(c => c.id !== classe.distancielClasse.id);
                              } else {
                                pkgs.push({
                                  id: classe.distancielClasse.id,
                                });
                              }

                              localStorage.setItem('bernCartPackages', JSON.stringify(pkgs));


                              // // Save is local storage
                              // let cart = localStorage.getItem('bernCartClasses');
                              // if (cart === null) {
                              //   cart = [];
                              // } else {
                              //   cart = JSON.parse(cart);
                              // }
                              // const index = cart.findIndex(cartClasse => cartClasse.id === classe.id);
                              // if (index !== -1) {
                              //   cart[index].tarifType = value;
                              // }

                              // localStorage.setItem('bernCartClasses', JSON.stringify(cart));


                              setPackages(prevPackages => {
                                const index = prevPackages.findIndex(pkg => pkg.id === classe.distancielClasse.id);
                                if (index !== -1) {
                                  prevPackages = prevPackages.filter(pkg => pkg.id !== classe.distancielClasse.id);
                                } else {
                                  prevPackages.push({ ...classe.distancielClasse, fromClasse: classe });
                                }

                                return [...prevPackages];
                              });

                              setTarifTypes(prevTarifTypes => {
                                const index = prevTarifTypes.findIndex(tt => tt.classeId === classe.distancielClasse.id);

                                console.log('zadm,azmnd', index);
                                if (index === -1) {
                                  prevTarifTypes.push({
                                    classeId: classe.distancielClasse.id,
                                    tarifType: 'specific',
                                    price: 30,
                                  });
                                } else {
                                  prevTarifTypes = prevTarifTypes.filter(ptt => ptt.classeId !== classe.distancielClasse.id);
                                }

                                return [...prevTarifTypes];
                              });
                            }}>
                              <form><Radio ghost checkedValue="presentiel" value={packages.find(pkg => pkg.id === classe.distancielClasse.id) ? "presentiel" : "distanciel"} /></form>
                              <div style={{ flex: 1 }}>
                                "Distanciel + Présentiel" - Je m’inscris au cours {classe.name} en présentiel ET en distanciel pour <strong>30€</strong> de plus.
                              </div>
                            </div>
                          }
                        </div>
                      </>
                    }
                    { classe.options && classe.options.length > 0 &&
                      <>
                        <h3>Choix des options : </h3>
                        {
                          classe.options.map(option => (
                            <div key={option.id} onClick={() => onClickOption(option)} className={`option${options.findIndex(op => op.id === option.id) !== -1 ? ' selected' : ''}`}>
                              <div style={{ flex: 1 }}>
                                <div style={{ fontWeight: 500, fontSize: '1.1em' }}>
                                  {option.name}
                                </div>
                                <div style={{ color: 'grey' }}>
                                  {option.description}
                                </div>
                              </div>
                              <div style={{ flex: '0 0 auto', textAlign: 'right' }}>
                                <span style={{ fontWeight: 500, fontSize: '1.1em' }}>{option.price}€</span>
                                <br />
                                {option.required ? 'Requis' : 'Non requis'}
                                <br />
                                {option.refundable ? 'Remboursable' : 'Non remboursable'}
                              </div>
                            </div>
                          ))
                        }
                        { classe.options.length === 0 &&
                          <div style={{ color: 'grey', textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>
                            Aucune option disponible.
                          </div>
                        }
                      </>
                    }
                  </>
                ) : (
                  <div style={{ color: 'red', paddingTop: '20px' }}>
                    Ce cours est complet. Vous ne pouvez plus vous y inscrire.
                    <br />Veuillez le retirer pour continuer.
                  </div>
                )}
              </div>
            ))
          }

          <form className="form">
            <h3>Informations de facturation : </h3>
            { error &&
              <div style={{ padding: '5px 0px', color: 'red', marginTop: '-10px' }}>
                {error}
              </div>
            }
            <Input label="Adresse *" {...form.field('address')} />
            <br />
            <div className="form-divid">
              <div style={{ flex: 1 }}>
                <Input label="Code postal *" {...form.field('zipcode')} />
              </div>
              <div className="separator" />
              <div style={{ flex: 1 }}>
                <Input label="Ville *" {...form.field('city')} />
              </div>
            </div>
            <br />
            <div className="form-divid">
              <div style={{ flex: 1 }}>
                <Input label="Pays *" {...form.field('country')} />
              </div>
              <div className="separator" />
              <div style={{ flex: 1 }}>
                <Input label="Téléphone *" {...form.field('phoneNumber')} />
              </div>
            </div>
          </form>

          <h3>Paiement : </h3>
          <CheckoutForm onPaid={(paymentMethod, threeTime) => onPaid(paymentMethod, threeTime)} classes={classes} options={options} tarifTypes={tarifTypes} checkAddress={() => checkAddress()} packages={packages} />
        </div>
        <div className="prices">
          <h3 style={{ marginBottom: '10px' }}>Vos informations :</h3>
          <div style={{ padding: '20px', background: 'white', borderRadius: '8px', border: '1px solid #ccc' }}>
            { auth.isAuthenticated && auth.user &&
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br />
                {auth.user.email}
              </>
            }
          </div>
          <h3 style={{ marginBottom: '0px' }}>Tarif :</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0px', borderBottom: '1px solid #ccc' }}>
            <div>
              Cours
            </div>
            <div>
              {classes.reduce((amount, classe) => amount + classe[tarifTypes.find(tt => tt.classeId === classe.id) ? tarifTypes.find(tt => tt.classeId === classe.id).tarifType : 'individualPriceTtc'], 0)}€
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0px', borderBottom: '1px solid #ccc' }}>
            <div>
              Options
            </div>
            <div>
              {options.reduce((price, option) => price + option.price, 0)}€
            </div>
          </div>
          { packages.length > 0 &&
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0px', borderBottom: '1px solid #ccc' }}>
              <div>
                Packages
              </div>
              <div>
                {30 * packages.length}€
              </div>
            </div>
          }
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0px' }}>
            <div style={{ fontWeight: 'bold' }}>
              Total TTC
            </div>
            <div>
              {30 * packages.length + tarifTypes.reduce((price, tarifType) => price + (tarifType.package ? 30 : 0), 0) + options.reduce((price, option) => price + option.price, classes.reduce((amount, classe) => amount + classe[tarifTypes.find(tt => tt.classeId === classe.id) ? tarifTypes.find(tt => tt.classeId === classe.id).tarifType : 'individualPriceTtc'], 0))}€
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
