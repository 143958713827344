import React, { useEffect, useState } from 'react';

import { Head } from '../../components/head';
import { Input, Select } from '../../hooks/form';
import { useList, List } from '../../hooks/list';
import { useModal } from '../../hooks/modal/Modal';

export default function AddClasseModal({ onAdded, many, period, excludeIds, year = 2023, excludeMooc = false }) {
  const modal = useModal();
  const { filters, items, isLoading, load } = useList('classes', {
    search: '',
    //channel: 'distanciel',
    year,
    period: (period || ['first-semester', 'second-semester']), // @see
    program: 'e-learning',
    distancielservice: 'etipi',
    excludeIds,
    excludeMooc,
  }, true);
  const [selectedClasses, setSelectedClasses] = useState([]);

  function select(classe) {
    setSelectedClasses(prevClasses => [...prevClasses, classe]);
  }

  useEffect(() => {
    // console.log(many, selectedClasses.length)
    if ((many === 'two' && selectedClasses.length === 1)  || (many === 'three' && selectedClasses.length === 2)) {
      onAdded(selectedClasses);
      modal.close();
    }
  }, [selectedClasses]);

  return (
    <div className="list-page" style={{ width: '100%', overflow: 'hidden' }}>
      <h1 style={{ background: 'black', color: 'white', margin: '0px', padding: '20px', borderRadius: '8px 8px 0px 0px' }}>Choisissez une classe</h1>
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        {/* <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('period')}>
            <option value={null}>Toutes périodes</option>
            <option value="annual">Annuel</option>
            <option value="first-semester">1er semestre</option>
            <option value="second-semester">2nd semestre</option>
          </Select>
        </div> */}
      </form>
      <List scrollableClassName=".component-modal" onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Nom', sort: 'name' }, { name: 'Séances' }]}>
        { items.filter(classe => classe.individualPriceTtc !== null && classe.individualPriceTtc !== 0).map(classe => (
          <div key={classe.id} className={`item${selectedClasses.findIndex(sClasse => sClasse.id === classe.id) !== -1 ? ' selected' : ''}`} onClick={() => select(classe)} >
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              {/* <div style={{ marginRight: '10px', borderRadius: '10px', background: 'green', width: '10px', height: '10px', flex: '0 0 auto' }}></div> */}
              <span>
                {classe.number} • {classe.name}
              </span>
            </div>
            <div className="prop">
              {classe.period ? { annual: 'Annuel', 'first-semester': '1er semestre', 'second-semester':'2nd semestre'}[classe.period] : ''} {classe.period && classe.year && <>•</>} {classe.year ? `${classe.year} - ${classe.year+1}` : ''}
            </div>
          </div>
        ))}
      </List>
    </div>
  )
}