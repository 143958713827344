import React, { useState } from 'react';
import './register.scss';
import Link from '../../hooks/router/Link';
import GuestLayout from '../../layouts/GuestLayout';
import { Checkbox, Input, Select, useForm } from '../../hooks/form';
import { useRouter } from '../../hooks/router';
import Validator from 'validator';
import Api from '../../services/Api';

export default function Register () {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    interests: {},
  }, (data, validate) => {
    setIsLoading(true);
    const result = validate(data, {
      civility: { validator: (civility) => ['mr', 'mrs', 'father', 'sister', 'brother', 'mgr'].includes(civility), message: 'Veuillez choisir une civilité' },
      email: { validator: (email) => Validator.isEmail(email), message: 'L\'email n\'est pas valide' },
      firstname: { validator: (firstname) => !Validator.isEmpty(firstname), message: 'Vous devez mettre un prénom' },
      lastname: { validator: (lastname) => !Validator.isEmpty(lastname), message: 'Vous devez mettre un nom' },
      password: { validator: (password) => (
        !Validator.isEmpty(password)
        && password.length >= 8
      ), message: 'Le mot de passe n\'est pas assez complexe' }
    });

    if (result.hasErrors) {
      form.setErrors(result.errors);
      setIsLoading(false);
      return;
    }

    Api.post(`/register`, data, null, true).then(response => {
      let query = {};
      const urlParams = new URLSearchParams(window.location.search);
      const redirect = urlParams.get('redirect');
      if (redirect) {
        query.redirect = redirect;
      }
      router.goTo('activate', { userId: response.data.userId }, query);
    }).catch(error => {
      setIsLoading(false);
      if (error.response && error.response && error.response.data && error.response.data.errors) {
        form.setErrors(error.response.data.errors);
      }
    });
  });

  console.log(form.errors);

  return (
    <GuestLayout>
      <form className="register" onSubmit={form.onSubmit}>
        <img src="/images/logo.png" alt="logo" />
        <div style={{ fontWeight: '700', marginBottom: '20px', textAlign: 'center' }}>Créer votre compte :</div>
        <Select label="Civilité" noSearch {...form.field('civility')}>
          <option value={null}>Non définie</option>
          <option value="mr">M.</option>
          <option value="mrs">Mme</option>
          <option value="father">Père</option>
          <option value="sister">Soeur</option>
          <option value="brother">Frère</option>
          <option value="mgr">Mgr</option>
        </Select>
        <Input label="Prénom" {...form.field('firstname', { placeholder: 'Prénom' })} />
        <Input label="Nom" {...form.field('lastname', { placeholder: 'Nom' })} />
        <Input label="Email" {...form.field('email', { placeholder: 'Email' })} />
        <Input label="Mot de passe" see {...form.field('password', { placeholder: 'Mot de passe', type: 'password' })} />
        <div style={{ fontSize: '0.9em', color: 'grey', paddingLeft: '5px', marginBottom: '10px' }}>Votre mot de passe doit être de 8 caractères minimum</div>

        <label htmlFor="">Choisissez vos centres d'interêts</label>
        <br />
        <Checkbox label="Art et création" {...form.field('interests.artcreation', { type: 'checkbox' })} />
        <br />
        <Checkbox label="Transmission, éducation, famille" {...form.field('interests.transmission', { type: 'checkbox' })} />
        <br />
        <Checkbox label="Economie, entreprise, environnement" {...form.field('interests.economie', { type: 'checkbox' })} />
        <br />
        <Checkbox label="Sciences, éthique et corps humain" {...form.field('interests.sciences', { type: 'checkbox' })} />
        <br />
        <Checkbox label="Histoire, démocratie, politique" {...form.field('interests.history', { type: 'checkbox' })} />
        <br />
        <Checkbox label="Formation biblique, philosophique, théologique" {...form.field('interests.formation', { type: 'checkbox' })} />
        <br />
        <Checkbox label="Christianisme, judaïsme et religions non chrétiennes" {...form.field('interests.christ', { type: 'checkbox' })} />
        <br />
        <Checkbox label="Numérique, nouvelles technologies" {...form.field('interests.numeric', { type: 'checkbox' })} />
        <br />
        <Checkbox label="Activités jeune public" {...form.field('interests.young', { type: 'checkbox' })} />
        <br />

        <hr/>
        <br/>
        <Checkbox label="S'inscrire à la newsletter" {...form.field('newsletter', { type: 'checkbox' })} />
        <br />

        <button className="btn" disabled={isLoading}>Créer mon compte</button>
        <hr style={{ margin: '20px 0px', background: '#E9E9E9' }} />
        <br />
        <Link className="btn" to="dashboard" query={{ redirect: (new URLSearchParams(window.location.search)).get('redirect') }}><i className="fas fa-arrow-left" /> Se connecter</Link>
      </form>
    </GuestLayout>
  );
};