import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './user.scss';
import { getCookie } from '../hooks/auth/session';
import { Loader } from '../components/loader';

export default function SearchBar() {
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [results, ] = useState([]);

  useEffect(() => {
    if (search !== '') {
      setIsLoading(true);
      (async () => {
        await axios.get(`http://localhost:8084/search?s=${search}`, {
          headers: { Authorization: `Bearer ${getCookie('xTokBernardinsStudentV2')}` },
        });
        setIsLoading(false);
      })();
    }
  }, [search]);
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <input placeholder="Rechercher" value={search} onChange={event => setSearch(event.target.value)} autoFocus type="text" style={{ outline: 'none', padding: '20px', fontSize: '1.2em', border: 'none', borderRadius: '4px', width: '100%' }} />
      { !isLoading && results.length > 0 &&
        <div className="results" style={{ padding: '20px', height: '400px', borderTop: '1px solid #ccc' }}>
          
        </div>
      }
      { !isLoading && results.length === 0 && search.length > 0 &&
        <div style={{ padding: '20px', fontSize: '2em', color: '#cecece', height: '400px', borderTop: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          Aucun résultats
        </div>
      }
      { isLoading &&
        <div style={{ padding: '20px', height: '400px', borderTop: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loader />
        </div>
      }
    </div>
  );
};
