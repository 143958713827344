import React from 'react';
import { Checkbox } from '../form';

export default function ListHeaders({ headers, selection }) {
  return (
    <div className="header">
      {
        headers.map(header => (
          <div key={header.select ? 'select' : header.name} className={`prop${header.select ? ' select' : ''}`} style={{ flex: (header.width || header.select) ? '0 0 auto' : (header.flex || 1), width: header.select ? '30px' : (header.width || 'auto') }}>
            { header.select ? (
              <form>
                <Checkbox disabled={!selection.isOneSelectable(/*data*/)} checked={selection.isAllSelected(/*data*/)} /*onClick={() => onAllSelect()}*/ inline />
              </form>
            ) : (
              <>
                {header.name}
              </>
            )}
          </div>
        ))
      }
    </div>
  );
};
