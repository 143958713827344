import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth/Auth';
import { Link } from '../../hooks/router';
import Api from '../../services/Api';
import './iframe-ccdej.scss';

export default function IFrameCCDEJ ({ route }) {
  const auth = useAuth();
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    Api.get(`/classes`, { year: 2021, type: 'public', ccdej: true, page: -1 }, null, true)
      .then(response => {
        setClasses(response.data);
      })
      .catch(error => {
        console.log(error);
        alert('Une erreur est survenue.');
      })
  }, [route.params.slug]);

  return (
    <div className="iframelist">
      <div className="iframe">
        <div className="page">
          <div className="sub">CENTRE CHRÉTIEN D'ÉTUDES JUIVES</div>
          <div className="title">UNE FORMATION À LA CARTE</div>
          <div className="desc">
            La connaissance du judaïsme et de ses liens avec la foi chrétienne vous intéresse ?
          </div>

          L’École Cathédrale propose de nombreux cours publics -semestriels ou annuels- sur le sujet.
          <br/>

          <div className="category">
            <div className="title">
              Inscriptions
            </div>
          </div>
          <p><span style={{ fontSize: '18px' }}><strong><span style={{ color: '#e74c3c' }}>À LIRE ATTENTIVEMENT AVANT DE VOUS INSCRIRE !</span></strong></span></p>

          <p><strong><span style={{ color: '#e74c3c' }}>Le Collège des Bernardins fait évoluer ses outils, et propose une nouvelle plateforme numérique dédiée, qui va s’enrichir au fur et à mesure de nouveaux services et enrichir votre expérience d’étudiant connecté.</span></strong></p>

          <p><strong><span style={{ color: '#e74c3c' }}>Pour vous inscrire, vous devez créer un nouveau compte pour accéder à votre espace étudiant et notamment à vos factures. Et ce, <u>même si vous possédiez déjà un compte</u>. Cela ne vous prendra que quelques minutes !</span></strong></p>

          <p><strong><span style={{ color: '#e74c3c' }}>Inscrivez-vous ou connectez-vous en cliquant sur les onglets à droite de la page (« Se connecter à mon compte étudiant » ou « Créer mon compte étudiant »)&nbsp;</span></strong></p>
          <br />
          Pour vous inscrire, cliquez sur l'intitulé de cours de votre choix :


          <div className="category">
            <div className="title">
              Session de rentrée du ccdej :
            </div>
          </div>

          <div className="classes">
            {
              classes.filter(cl => cl.ccdejCategory === 'entrance-session').sort((a, b) => a.number - b.number).map(classe => (
                <Link to="iframe-classe" params={{ slug: classe.id }} className="link">
                  {classe.number} - {classe.name}
                </Link>
              ))
            }
          </div>

          <div className="category">
            <div className="title">
              Cours annuels d’hébreu :
            </div>
          </div>
          <div className="classes">
            {
              classes.filter(cl => cl.ccdejCategory === 'annual-hebrew-courses').sort((a, b) => a.number - b.number).map(classe => (
                <Link to="iframe-classe" params={{ slug: classe.id }} className="link">
                  {classe.number} - {classe.name}
                </Link>
              ))
            }
          </div>

          <div className="category">
            <div className="title">
              Cours du premier semestre :
            </div>
          </div>
          <div className="classes">
            {
              classes.filter(cl => cl.ccdejCategory === 'first-semester-course').sort((a, b) => a.number - b.number).map(classe => (
                <Link to="iframe-classe" params={{ slug: classe.id }} className="link">
                  {classe.number} - {classe.name}
                </Link>
              ))
            }
          </div>

          <div className="category">
            <div className="title">
              Sessions intersemestrielles :
            </div>
          </div>
          <div className="classes">
            {
              classes.filter(cl => cl.ccdejCategory === 'inter-semester-sessions').sort((a, b) => a.number - b.number).map(classe => (
                <Link to="iframe-classe" params={{ slug: classe.id }} className="link">
                  {classe.number} - {classe.name}
                </Link>
              ))
            }
          </div>

          <div className="category">
            <div className="title">
              Cours du second semestre :
            </div>
          </div>
          <div className="classes">
            {
              classes.filter(cl => cl.ccdejCategory === 'second-semester-course').sort((a, b) => a.number - b.number).map(classe => (
                <Link to="iframe-classe" params={{ slug: classe.id }} className="link">
                  {classe.number} - {classe.name}
                </Link>
              ))
            }
          </div>

          <div className="category">
            <div className="title">
              Dimanche de découverte du judaïsme :
            </div>
          </div>
          <div className="classes">
            {
              classes.filter(cl => cl.ccdejCategory === 'sunday-of-discovery-of-judaism').sort((a, b) => a.number - b.number).map(classe => (
                <Link to="iframe-classe" params={{ slug: classe.id }} className="link">
                  {classe.number} - {classe.name}
                </Link>
              ))
            }
          </div>
          
        </div>
      </div>
      <div className="menu">
        { auth.isAuthenticated ? (
          <>
            { auth.user ? (
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br/><br/>
                <Link className="light" to="dashboard" target>Mon compte étudiant</Link>
                <span style={{ cursor: 'pointer', textDecoration: 'underline', marginTop: '5px', display: 'block' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            ) : (
              <>Chargement</>
            )}
            <br />
          </>
        ) : (
          <>
            <Link to="dashboard" query={{ redirect: encodeURIComponent(`iframe/ccdej`) }}>
              Se connecter à mon compte étudiant
            </Link>
            <Link to="register" query={{ redirect: encodeURIComponent(`iframe/ccdej`) }}>
              Créer mon compte étudiant
            </Link>
          </>
        )}
        <hr/>
        <br />
        <Link to="checkout">Voir mon panier</Link>
        <br/>
        <Link to="iframe" params={{ semester: 'first' }}>
          Cours du 1er semestre
        </Link>
        <Link to="iframe" params={{ semester: 'second' }}>
          Cours du 2nd semestre
        </Link>
        <Link to="iframe-ccdej">
          Cours CCDEJ
        </Link>
      </div>
    </div>
  );
};
