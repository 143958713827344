import React, { useState } from 'react';
import { useAuth } from "../hooks/auth/Auth";
import { useRouter } from '../hooks/router';
import { Loader } from '../components/loader';
import { useEffect } from 'react';
import Api from '../services/Api';
import { getCookie, setCookie } from '../hooks/auth/session';

export default function Auth () {
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (router.route.query === undefined || router.route.query.code === undefined || router.route.query.code === '' || router.route.query.error === 'login_required') {
      // Redirect to sso if not authenticated
      //setCookie('auth_redirect', window.location.pathname);
      window.location.href = 'https://account.collegedesbernardins.fr/login?client_id=32379462d2ca4b7eb50e43839b090c86&redirect_uri=https://alpha.collegedesbernardins.fr/auth'
    } else {
      Api.post(`/sso`, {
        code: router.route.query.code
      }).then((response) => {
        auth.forceLogin(response.data.access_token, response.data.user);
        const authRedirect = getCookie('auth_redirect');
        if (authRedirect) {
          router.goToPageUrl(authRedirect);
        } else {
          router.goToPageUrl('/');
        }
      });
    }
  }, []);

  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Loader />
    </div>
  );
};
