import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Api from '../../services/Api';
import './etipi.scss';
import { useAuth } from '../../hooks/auth/Auth';
import { Radio, Input, useForm, useUpload } from '../../hooks/form';
import { Loader } from '../../components/loader';

import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { useModal } from '../../hooks/modal/Modal';
import AddClasseModal from './AddClasseModal';
import { setCookie } from '../../hooks/auth/session';

const CheckoutForm = ({ onPaid, checkAddress, classes, tarifType, packageType, classe }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    if (!checkAddress()) {
      setIsLoading(false);
      return;
    }

    Api.post(`/generate-payment-intent`, {
      classes,
      classe,
      options: [],
      tarifType: tarifType,
      package: packageType,
    }).then(({ data }) => {
      const cardElement = elements.getElement(CardNumberElement);
      stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: cardElement
        }
      }).then(function(result) {
        if (result.error) {
          console.log('NOOOOON')
          setError(result.error.message);
          // Show error to your customer (e.g., insufficient funds)
          console.log(result.error);
          setIsLoading(false);
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            setIsLoading(false);
            console.log('YESSSSS');
            onPaid(result.paymentIntent);
          }
        }
      });
    }).catch(() => {
      alert('Une erreur est survenue');
      setIsLoading(false);
    });
  };

  return (
    <form className="form paiment" onSubmit={handleSubmit}>
      <label htmlFor="lastname">Numéro de carte *</label>
      <div className="input"><CardNumberElement /></div>
      <div className="box" style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <label htmlFor="country">Date d'expiration *</label>
          <div className="input"><CardExpiryElement /></div>
        </div>
        <div style={{ flex: 1, marginLeft: '20px' }}>
          <label htmlFor="phone">CVC *</label>
          <div className="input"><CardCvcElement /></div>
        </div>
      </div>
      { error &&
        <div style={{ padding: '5px', color: 'red', marginTop: '-10px' }}>
          {error}
        </div>
      }

      <div className="ctas">      
        <button className="btn" type="submit" disabled={isLoading || !stripe}>
          Payer par carte bancaire
        </button>
      </div>

      <br/>
      <br/>
      *champs obligatoires
    </form>
  );
};

export default function Etipi ({ route }) {
  const modal = useModal();
  const auth = useAuth();
  const [classe, setClasse] = useState(null);
  const [classes, setClasses] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState();
  const [selectedSession, setSelectedSession] = useState(null);
  const form = useForm({
    tarifType: 'individualPriceTtc',
    package: null,

    address: '',
    zipcode: '',
    city: '',
    country: '',
    phoneNumber: '',
  });
  const [openAddress, setOpenAddress] = useState(false);
  const [isLoadingRegistration, setIsLoadingRegistration] = useState(false);
  const [success, setSuccess] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const { start, file, reset } = useUpload({ onUpload: (file) => {
    setUploadLoading(true);
    console.log(file);
    Api.upload(`/files`, file, ({ percent }) => {
      //setUploadLoading(prevState => ({ ...prevState, percent }));
    }).then(response => {
      setUploadFile(response.data);
      setUploadLoading(false);
    }).catch(error => {
      setUploadFile(null);
      console.log(error);
      setUploadLoading(false);
      reset();
      alert('Une erreur est survenue');
    });
  } });

  useEffect(() => {
    if (!auth.isAuthenticated) {
      // Redirect to sso if not authenticated
      setCookie('auth_redirect', window.location.pathname);
      window.location.href = 'https://account.collegedesbernardins.fr/login?client_id=32379462d2ca4b7eb50e43839b090c86&redirect_uri=https://alpha.collegedesbernardins.fr/auth'
    } else {
      Api.get(`/classes/${route.params.slug}`)
        .then(response => {
          if (response && response.data && response.data.channel === 'distanciel') {
            setClasse(response.data);
            setSelectedSession(response.data.sessions.length > 0 ? response.data.sessions[0] : null);
          } else {
            setNotFound(true);
          }
        })
        .catch(error => {
          setNotFound(true);
        });
  
      // Retrieve address if exists
      Api.get('/me', { address: true })
        .then((response) => {
          if (response.data.address) {
            form.setValue('address', response.data.address.address);
            form.setValue('zipcode', response.data.address.zipcode);
            form.setValue('city', response.data.address.city);
            form.setValue('country', response.data.address.country);
            form.setValue('phoneNumber', response.data.address.phoneNumber);
          }
        });
    }
  }, [route.params.slug]);

  function onPaid(paymentIntent) {
    setIsLoadingRegistration(true);

    Api.post('/checkout', {
      distancielId: classe.distancielId,
      distancielSessionId: selectedSession ? selectedSession.externalId : null,
      classe,
      classes,
      options: [],
      tarifType: form.data.tarifType,
      package: form.data.package,
      billingAddress: form.data,
      paymentIntent,
      uploadFile
    }).then(() => {
      setSuccess(true);
    }).catch(() => {
      alert('Une erreur est survenue, veuillez contacter le collège des bernardins.');
      setIsLoadingRegistration(false);
    })
  }

  function checkAddress() {
    // test form
    const err = {};

    if (form.data.address.length < 1) {
      err.address = 'Veuillez remplir le champs.';
    }
    if (form.data.zipcode.length < 1) {
      err.zipcode = 'Veuillez remplir le champs.';
    }
    if (form.data.city.length < 1) {
      err.city = 'Veuillez remplir le champs.';
    }
    if (form.data.country.length < 1) {
      err.country = 'Veuillez remplir le champs.';
    }
    if (form.data.phoneNumber.length < 1) {
      err.phoneNumber = 'Veuillez remplir le champs.';
    }

    if (form.data.tarifType === 'youthPriceTtc' && uploadFile === null) {
      err.tarifType = 'Veuillez joindre un document justificatif.';
    }

    if (Object.keys(err).length > 0) {
      form.setErrors(err);
      return false;
    } else {
      form.setErrors({});
    }
    return true;
  }

  function chooseClasse(many) {
    if (form.data.package === many) {
      return form.setValue('package', null);
    }
    modal.open(<AddClasseModal onAdded={classes => {
      setClasses(classes);
      form.setValue('package', many);
    }} many={many} excludeIds={[classe.id, 749, 752, 751, 753, 750, 426 /** Demande ticket Geoffroy @see https://www.notion.so/Retirer-le-cours-Initiation-la-foi-de-l-glise-annuel-22-23-des-packages-2-ou-3-Libres-Cours-73d18ef93e96435aa6c06a96342e6a87 */]} year={`2022,2023`} excludeMooc period={[/** all periods */]} />, { size: 'full' });
  }

  if (!auth.isAuthenticated) {
    return (
      <div className="etipi" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </div>
    );
  }

  if (classe && classe.deletedAt !== null) {
    return (
      <div className="etipi">
        <div className="header">
          <img className="logo" src="/new-logo.png" alt=""/>
          <div className="identity">
            { auth.isAuthenticated && auth.user &&
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br />
                {auth.user.email}
                <br />
                <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            }
          </div>
        </div>

        <div className="bar"></div>

        <div className="page">

          <div style={{ padding: '50px 0px', textAlign: 'center' }}>
            <div>
              Aucun cours ne correspond à votre demande.
            </div>
            <br/>
            <a href="https://www.leslibrescours.collegedesbernardins.fr/" className="btn">Aller voir tous les cours</a>
          </div>
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="etipi">
        <div className="header">
          <img className="logo" src="/new-logo.png" alt=""/>
          <div className="identity">
            { auth.isAuthenticated && auth.user &&
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br />
                {auth.user.email}
                <br />
                <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            }
          </div>
        </div>

        <div className="bar"></div>

        <div className="page">

          <div style={{ padding: '50px 0px', textAlign: 'center' }}>
            <div style={{ marginBottom: '10px', fontSize: '1.6em', color: '#d29e3a' }}>Votre inscription a été validée</div>
            <div style={{ marginBottom: '20px', color: 'grey' }}>Vous allez recevoir un message de confirmation à l’adresse mail de votre compte.</div>
            <br />
            <br />
            <br/>
            <a href={classe.distancielLink} className="btn">Aller voir mon cours</a>
          </div>
        </div>
      </div>
    )
  }

  if (isLoadingRegistration) {
    return (
      <div className="etipi">
        <div className="header">
          <img className="logo" src="/new-logo.png" alt=""/>
          <div className="identity">
            { auth.isAuthenticated && auth.user &&
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br />
                {auth.user.email}
                <br />
                <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            }
          </div>
        </div>

        <div className="bar"></div>

        <div className="page">

          <div style={{ padding: '50px 0px', textAlign: 'center' }}>

            <div style={{ padding: '50px 0px', textAlign: 'center' }}>
              <div style={{ marginBottom: '10px', fontSize: '1.6em' }}>Votre inscription est en cours ..</div>
              <div style={{ marginBottom: '20px', color: 'grey' }}>Cela peut prendre quelque secondes.</div>
              <Loader />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="etipi">
      <div className="header">
        <a href="https://www.lecampusdesbernardins.fr/">
          <img className="logo" src="/new-logo.png" alt=""/>
        </a>
        <div className="identity">
          { auth.isAuthenticated && auth.user &&
            <>
              {auth.user.firstname} {auth.user.lastname}
              <br />
              {auth.user.email}
              <br />
              <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
            </>
          }
        </div>
      </div>

      <div className="bar"></div>

      <div className="page">
        { notFound && 
          <div className="no-classe">
            Le cours demandé n'existe pas.
          </div>
        }
        { !notFound && !classe &&
          <div style={{ padding: '50px 0px' }}>
            <Loader />
          </div>
        }
        { !notFound && classe &&
          <>
            { (classe.individualPriceTtc === null || classe.individualPriceTtc === 0) ? (
              <h3>Vous souhaitez vous inscrire au MOOC suivant :</h3>
            ) : (
              <h3>Vous souhaitez vous inscrire (en distanciel) au <i>Libre Cours</i> suivant :</h3>
            )}
            <div className="panel">
              <div className="classe">
                <div className="infos">
                  <div className="title">{classe.name}</div>
                  {/* <div className="professors">Cours assuré par {classe.professors.map(({ professor }) => `${professor.user.firstname} ${professor.user.lastname}`).join(', ')}</div> */}
                </div>
              </div>
            </div>

            { classe.alreadyRegistered &&
              <div className="warning" style={{ color: 'red', padding: '50px 20px', textAlign: 'center' }}>
                Vous êtes déjà inscrit à ce cours
              </div>
            }

            { !classe.alreadyRegistered &&
              <>
                { ((classe.individualPriceTtc !== null && classe.individualPriceTtc !== 0) || classe.sessions.filter(session => session.status !== 'deleted').length > 0) &&
                  <h3>
                    <div className="bullet">1</div>
                    { classe.sessions.length > 0 ? (
                      <>Veuillez choisir une session{ (classe.individualPriceTtc !== null && classe.individualPriceTtc !== 0) ? ' et son tarif' : ''}</>
                    ) : (
                      <>Veuillez choisir votre tarif</>
                    )}
                  </h3>
                }
                <div className="panel">
                  { classe.sessions.filter(session => session.status !== 'deleted').length > 0 &&
                    <div className="sessions">
                      {
                        classe.sessions.filter(session => session.status === 'created').map(session => (
                          <div className={`session${selectedSession && session.id === selectedSession.id ? ' active' : ''}`} onClick={() => setSelectedSession(session)}>
                            Commence le {moment(session.startAt).format('DD/MM/YYYY')}
                            <br />
                            Fini le {moment(session.endAt).format('DD/MM/YYYY')}
                            <br />
                            Dure {session.numberHours} heures sur {session.numberDays} jours
                          </div>
                        ))
                      }
                    </div>
                  }
                  { (classe.individualPriceTtc !== null && classe.individualPriceTtc !== 0) &&
                    <div className="tarifs">
                      <div className="plans">
                        {/* <div className={`plan${form.data.tarifType === 'fullPriceTtc' ? ' active' : ''}`} onClick={() => form.setValue('tarifType', 'fullPriceTtc')}>
                          Tarif
                          <br/>
                          Entreprises/institutions
                          { form.data.package === null &&
                            <div className="number">
                              {classe.fullPriceTtc}€
                            </div>
                          }
                        </div> */}
                        <div className={`plan${form.data.tarifType === 'individualPriceTtc' ? ' active' : ''}`} onClick={() => form.setValue('tarifType', 'individualPriceTtc')}>
                          Tarif
                          <br/>
                          Individuel
                          { form.data.package === null &&
                            <div className="number">
                              {classe.individualPriceTtc}€
                            </div>
                          }
                        </div>
                        <div className={`plan${form.data.tarifType === 'youthPriceTtc' ? ' active' : ''}`} onClick={() => form.setValue('tarifType', 'youthPriceTtc')}>
                          Tarif
                          <br/>
                          Moins de 30 ans
                          { form.data.package === null &&
                            <div className="number">
                              {classe.youthPriceTtc}€
                            </div>
                          }
                        </div>
                      </div>

                      <div style={{ fontSize: '0.9em', color: 'grey', marginTop: '-10px', textAlign: 'center' }}>
                        Tarif entreprises/institutions sur demande à <a href="mailto:contact@lecampusdesbernardins.fr">contact@lecampusdesbernardins.fr</a>
                      </div>

                      { form.data.tarifType === 'youthPriceTtc' &&
                        <>
                          <div className="document">
                            { !file &&
                              <>
                                Pour bénéficier du tarif moins de 30 ans, veuillez joindre un document justificatif :
                                <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
                                  <button className="btn" onClick={() => start()}>Choisir un document</button>
                                </div>
                              </>
                            }
                            { file &&
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                  Document justificatif : <strong>{file.name}</strong> • {(file.size/1024).toFixed(2)}mo • {file.type}
                                </div>
                                { uploadLoading &&
                                  <div style={{ margin: '-4px 0px' }}>
                                    <Loader />
                                  </div>
                                }
                                <div className="remove" onClick={(event) => {event.stopPropagation();reset()}} style={{ lineHeight: '0px', marginLeft: '10px', cursor: 'pointer' }}>
                                  <svg version="1.1" viewBox="0 0 20 20" x="0px" y="0px" style={{ width: '20px', height: '20px' }}><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
                                </div>
                              </div>
                            }
                          </div>
                          { form.errors.tarifType && <div style={{ color: 'red', padding: '5px' }}>{form.errors.tarifType}</div> }
                        </>
                      }
                    </div>
                  }
                </div>
                
                { ((classe.period !== 'annual' && classe.mooc !== true && classe.id !== 749 && classe.id !== 752 && classe.id !== 751 && classe.id !== 753 && classe.id !== 750) || ( classe.presentielClasse !== null )) &&
                  <>
                    <h3>
                      <div className="bullet">
                        {(classe.sessions.filter(session => session.status !== 'deleted').length > 0) ? 2 : 1}  
                      </div>
                      { classe.period === 'annual' ? (
                        <>Notre package vous intéresse-t-il ?</>
                      ) : (
                        <>L’un de nos packages vous intéresse-t-il ?</>
                      )}
                    </h3>
                    <div className="packages">
                      { classe.period !== 'annual' && classe.id !== 749 && classe.id !== 752 && classe.id !== 751 && classe.id !== 753 && classe.id !== 750 &&
                        <>
                          <div className="package" onClick={() => chooseClasse('two')}>
                            <form><Radio ghost checkedValue="two" {...form.field('package', { type: 'radio' })} /></form>
                            <div>
                              "2&nbsp;<i>Libres Cours</i>" - Je m’inscris à deux cours en distanciel pour un montant de 160€
                              <br />
                              { form.data.package === 'two' && classes[0] &&
                                <>
                                  <br />
                                  • <strong>{classes[0].name}</strong>
                                </>
                              }
                            </div>
                          </div>
                          <div className="package" onClick={() => chooseClasse('three')}>
                            <form><Radio ghost checkedValue="three" {...form.field('package', { type: 'radio' })} /></form>
                            <div>
                              "3&nbsp;<i>Libres Cours</i>" - Je m’inscris à trois cours en distanciel pour un montant de 200€
                              <br />
                              { form.data.package === 'three' && classes[0] &&
                                <>
                                  <br />
                                  • <strong>{classes[0].name}</strong>
                                </>
                              }
                              { form.data.package === 'three' && classes[1] &&
                                <>
                                  <br />
                                  • <strong>{classes[1].name}</strong>
                                </>
                              }
                            </div>
                          </div>
                        </>
                      }
                      { classe.presentielClasse &&
                        <div className="package" onClick={() => form.setValue('package', prevPackage => prevPackage === 'presentiel' ? null : 'presentiel')}>
                          <form><Radio ghost checkedValue="presentiel" {...form.field('package', { type: 'radio' })} /></form>
                          <div style={{ flex: 1 }}>
                            "Distanciel + Présentiel" - Je m’inscris au cours {classe.presentielClasse.name} en distanciel ET en présentiel pour {classe.presentielClasse[form.data.tarifType]}€ (montant du cours en présentiel) + 30€
                          </div>
                        </div>
                      }
                    </div>
                  </>
                }
                
                { (classe.individualPriceTtc !== null && classe.individualPriceTtc !== 0) &&
                  <>
                    <form className="infos">
                      <h3><div className="bullet">
                        {((classe.individualPriceTtc !== null && classe.individualPriceTtc !== 0) || classe.sessions.filter(session => session.status !== 'deleted').length > 0) ? 3 : 1}  
                      </div>Informations de facturation :</h3>
                      { error &&
                        <div style={{ padding: '5px 0px', color: 'red', marginTop: '-10px' }}>
                          {error}
                        </div>
                      }
                      {/* { form.data.address !== null && form.data.address !== '' && openAddress === false &&
                        <div className="address">
                          <div>
                            {form.data.address}
                            <br />
                            {form.data.zipcode} {form.data.city}
                            <br />
                            {form.data.country}
                            <br />
                            {form.data.phoneNumber}
                          </div>
                          <div className="update" onClick={() => setOpenAddress(true)}>Modifier</div>
                        </div>
                      } */}
                      {/* { (openAddress || form.data.address === null || form.data.address === '') &&
                        <> */}
                          <Input label="Adresse *" {...form.field('address')} />
                          <br />
                          <div className="form-divid">
                            <div style={{ flex: 1 }}>
                              <Input label="Code postal *" {...form.field('zipcode')} />
                            </div>
                            <div className="separator" />
                            <div style={{ flex: 1 }}>
                              <Input label="Ville *" {...form.field('city')} />
                            </div>
                          </div>
                          <br />
                          <div className="form-divid">
                            <div style={{ flex: 1 }}>
                              <Input label="Pays *" {...form.field('country')} />
                            </div>
                            <div className="separator" />
                            <div style={{ flex: 1 }}>
                              <Input label="Téléphone *" {...form.field('phoneNumber')} />
                            </div>
                          </div>
                        {/* </>
                      } */}
                    </form>
                    <h3><div className="bullet">4</div>Paiement :</h3>
                    <CheckoutForm onPaid={(paymentIntent) => onPaid(paymentIntent)} classe={classe} packageType={form.data.package} tarifType={form.data.tarifType} classes={classes} checkAddress={() => checkAddress()} />
                  </>
                }
                { (classe.individualPriceTtc === null || classe.individualPriceTtc === 0) &&
                  <form className="form paiment" onSubmit={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    onPaid();
                  }}>
                    <br />
                    <div className="ctas">      
                      <button className="btn" type="submit">
                        Confirmer
                      </button>
                    </div>
                  </form>
                }
              </>
            }
          </>
        }
      </div>
    </div>
  );
};
