import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from '../../hooks/router';
import Api from '../../services/Api';
import './etipi.scss';
import { useAuth } from '../../hooks/auth/Auth';
import { Checkbox, Radio, Input, Select, useForm, useUpload } from '../../hooks/form';
import { Loader } from '../../components/loader';

import { Elements, useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useModal } from '../../hooks/modal/Modal';
import AddClasseModal from './AddClasseModal';
import { setCookie } from '../../hooks/auth/session';
import AddProductModal from './AddProductModal';

const CheckoutForm = ({ onPaid, checkAddress, price, product, products, packageType }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    if (!checkAddress()) {
      setIsLoading(false);
      return;
    }

    Api.post(`/generate-payment-intent-product`, {
      products,
      package: packageType,
      product,
      price,
    }).then(({ data }) => {
      const cardElement = elements.getElement(CardNumberElement);
      stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: cardElement
        }
      }).then(function(result) {
        if (result.error) {
          console.log('NOOOOON')
          setError(result.error.message);
          // Show error to your customer (e.g., insufficient funds)
          console.log(result.error);
          setIsLoading(false);
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            setIsLoading(false);
            console.log('YESSSSS');
            onPaid(result.paymentIntent);
          }
        }
      });
    }).catch(() => {
      alert('Une erreur est survenue');
      setIsLoading(false);
    });
  };

  return (
    <form className="form paiment" onSubmit={handleSubmit}>
      <label htmlFor="lastname">Numéro de carte *</label>
      <div className="input"><CardNumberElement /></div>
      <div className="box" style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <label htmlFor="country">Date d'expiration *</label>
          <div className="input"><CardExpiryElement /></div>
        </div>
        <div style={{ flex: 1, marginLeft: '20px' }}>
          <label htmlFor="phone">CVC *</label>
          <div className="input"><CardCvcElement /></div>
        </div>
      </div>
      { error &&
        <div style={{ padding: '5px', color: 'red', marginTop: '-10px' }}>
          {error}
        </div>
      }

      <div className="ctas">      
        <button className="btn" type="submit" disabled={isLoading || !stripe}>
          Payer par carte bancaire
        </button>
      </div>

      <br/>
      <br/>
      *champs obligatoires
    </form>
  );
};

export default function Audio ({ route }) {
  const modal = useModal();
  const auth = useAuth();
  const [product, setProduct] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState();
  const [price, setPrice] = useState(0);
  const [products, setProducts] = useState([]);
  const form = useForm({
    tarifType: 'individualPriceTtc',
    package: null,

    address: '',
    zipcode: '',
    city: '',
    country: '',
    phoneNumber: '',
  });
  const [openAddress, setOpenAddress] = useState(false);
  const [isLoadingBuying, setIsLoadingBuying] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      // Redirect to sso if not authenticated
      setCookie('auth_redirect', window.location.pathname);
      window.location.href = 'https://account.collegedesbernardins.fr/login?client_id=32379462d2ca4b7eb50e43839b090c86&redirect_uri=https://alpha.collegedesbernardins.fr/auth'
    } else {
      Api.get(`/products/${route.params.slug}`)
        .then(response => {
          if (response && response.data && response.data.type === 'audio') {
            setProduct(response.data);
            setPrice(response.data.price);
          } else {
            setNotFound(true);
          }
        })
        .catch(error => {
          setNotFound(true);
        });
  
      // Retrieve address if exists
      Api.get('/me', { address: true })
        .then((response) => {
          if (response.data.address) {
            form.setValue('address', response.data.address.address);
            form.setValue('zipcode', response.data.address.zipcode);
            form.setValue('city', response.data.address.city);
            form.setValue('country', response.data.address.country);
            form.setValue('phoneNumber', response.data.address.phoneNumber);
          }
        });
    }
  }, [route.params.slug]);

  function onPaid(paymentIntent) {
    setIsLoadingBuying(true);

    // alert('TODO checkout')
    Api.post('/audio-checkout', {
      product,
      products,
      package: form.data.package,
      billingAddress: form.data,
      paymentIntent
    }).then(() => {
      setSuccess(true);
    }).catch(() => {
      alert('Une erreur est survenue, veuillez contacter le collège des bernardins.');
      setIsLoadingBuying(false);
    })
  }

  function checkAddress() {
    // test form
    const err = {};

    if (form.data.address.length < 1) {
      err.address = 'Veuillez remplir le champs.';
    }
    if (form.data.zipcode.length < 1) {
      err.zipcode = 'Veuillez remplir le champs.';
    }
    if (form.data.city.length < 1) {
      err.city = 'Veuillez remplir le champs.';
    }
    if (form.data.country.length < 1) {
      err.country = 'Veuillez remplir le champs.';
    }
    if (form.data.phoneNumber.length < 1) {
      err.phoneNumber = 'Veuillez remplir le champs.';
    }

    if (Object.keys(err).length > 0) {
      form.setErrors(err);
      return false;
    } else {
      form.setErrors({});
    }
    return true;
  }

  function chooseAudio(many) {
    if (form.data.package === many) {
      return form.setValue('package', null);
    }
    modal.open(<AddProductModal onAdded={products => {
      setProducts(products);
      form.setValue('package', many);
    }} many={many} excludeIds={[product.id]} price={24} />, { size: 'full' });
  }

  if (!auth.isAuthenticated) {
    return (
      <div className="etipi" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </div>
    );
  }

  if (product && product.deletedAt !== null) {
    return (
      <div className="etipi">
        <div className="header">
          <img className="logo" src="/new-logo.png" alt=""/>
          <div className="identity">
            { auth.isAuthenticated && auth.user &&
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br />
                {auth.user.email}
                <br />
                <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            }
          </div>
        </div>

        <div className="bar"></div>

        <div className="page">

          <div style={{ padding: '50px 0px', textAlign: 'center' }}>
            <div>
              Aucun audio ne correspond à votre demande.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="etipi">
        <div className="header">
          <img className="logo" src="/new-logo.png" alt=""/>
          <div className="identity">
            { auth.isAuthenticated && auth.user &&
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br />
                {auth.user.email}
                <br />
                <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            }
          </div>
        </div>

        <div className="bar"></div>

        <div className="page">

          <div style={{ padding: '50px 0px', textAlign: 'center' }}>
            <div style={{ marginBottom: '10px', fontSize: '1.6em', color: '#d29e3a' }}>Votre achat a été validé</div>
            <div style={{ marginBottom: '20px', color: 'grey' }}>Vous allez recevoir un message de confirmation à l’adresse mail de votre compte.</div>
            <br />
            <br />
            <br/>
            <a href={product.externalRedirectUrl} className="btn">Voir mon cours audio</a>
          </div>
        </div>
      </div>
    )
  }

  if (isLoadingBuying) {
    return (
      <div className="etipi">
        <div className="header">
          <img className="logo" src="/new-logo.png" alt=""/>
          <div className="identity">
            { auth.isAuthenticated && auth.user &&
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br />
                {auth.user.email}
                <br />
                <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            }
          </div>
        </div>

        <div className="bar"></div>

        <div className="page">

          <div style={{ padding: '50px 0px', textAlign: 'center' }}>

            <div style={{ padding: '50px 0px', textAlign: 'center' }}>
              <div style={{ marginBottom: '10px', fontSize: '1.6em' }}>Votre achat est en cours ..</div>
              <div style={{ marginBottom: '20px', color: 'grey' }}>Cela peut prendre quelque secondes.</div>
              <Loader />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="etipi">
      <div className="header">
        <a href="https://www.lecampusdesbernardins.fr/">
          <img className="logo" src="/new-logo.png" alt=""/>
        </a>
        <div className="identity">
          { auth.isAuthenticated && auth.user &&
            <>
              {auth.user.firstname} {auth.user.lastname}
              <br />
              {auth.user.email}
              <br />
              <span style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block', marginTop: '5px' }} onClick={() => auth.signout()}>Se déconnecter</span>
            </>
          }
        </div>
      </div>

      <div className="bar"></div>

      <div className="page">
        { notFound && 
          <div className="no-classe">
            L'audio demandé n'existe pas.
          </div>
        }
        { !notFound && !product &&
          <div style={{ padding: '50px 0px' }}>
            <Loader />
          </div>
        }
        { !notFound && product &&
          <>
            <h3>Vous souhaitez acheter le cours audio suivant :</h3>
            <div className="panel">
              <div className="classe">
                <div className="infos">
                  <div className="title">{product.name}</div>
                </div>
              </div>
            </div>

            <div className="tarifs">
              <div className="plans">
                <div className={`plan active`}>
                  Tarif unique
                  <div className="number">{price}€</div>
                </div>
              </div>
            </div>

            { product.price === 24 &&
              <>
                <h3>
                  <div className="bullet">
                    1
                  </div>
                  L’un de nos packages vous intéresse-t-il ?
                </h3>
                <div className="packages">
                  <div className="package" onClick={() => chooseAudio('two')}>
                    <form><Radio ghost checkedValue="two" {...form.field('package', { type: 'radio' })} /></form>
                    <div>
                      <i>"2&nbsp;cours audios"</i> - 40€
                      <br />
                      { form.data.package === 'two' && products[0] &&
                        <>
                          <br />
                          • <strong>{products[0].name}</strong>
                        </>
                      }
                    </div>
                  </div>
                  <div className="package" onClick={() => chooseAudio('three')}>
                    <form><Radio ghost checkedValue="three" {...form.field('package', { type: 'radio' })} /></form>
                    <div>
                      <i>"3&nbsp;cours audios"</i> - 56€
                      <br />
                      { form.data.package === 'three' && products[0] &&
                        <>
                          <br />
                          • <strong>{products[0].name}</strong>
                        </>
                      }
                      { form.data.package === 'three' && products[1] &&
                        <>
                          <br />
                          • <strong>{products[1].name}</strong>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </>
            }
            
            <form className="infos">
              <h3><div className="bullet">
                { product.price === 24  ? <>2</> : <>1</> }
              </div>Informations de facturation :</h3>
              { error &&
                <div style={{ padding: '5px 0px', color: 'red', marginTop: '-10px' }}>
                  {error}
                </div>
              }
              {/* { form.data.address !== null && form.data.address !== '' && openAddress === false &&
                <div className="address">
                  <div>
                    {form.data.address}
                    <br />
                    {form.data.zipcode} {form.data.city}
                    <br />
                    {form.data.country}
                    <br />
                    {form.data.phoneNumber}
                  </div>
                  <div className="update" onClick={() => setOpenAddress(true)}>Modifier</div>
                </div>
              } */}
              {/* { (openAddress || form.data.address === null || form.data.address === '') &&
                <> */}
                  <Input label="Adresse *" {...form.field('address')} />
                  <br />
                  <div className="form-divid">
                    <div style={{ flex: 1 }}>
                      <Input label="Code postal *" {...form.field('zipcode')} />
                    </div>
                    <div className="separator" />
                    <div style={{ flex: 1 }}>
                      <Input label="Ville *" {...form.field('city')} />
                    </div>
                  </div>
                  <br />
                  <div className="form-divid">
                    <div style={{ flex: 1 }}>
                      <Input label="Pays *" {...form.field('country')} />
                    </div>
                    <div className="separator" />
                    <div style={{ flex: 1 }}>
                      <Input label="Téléphone *" {...form.field('phoneNumber')} />
                    </div>
                  </div>
                {/* </>
              } */}
            </form>
            <br />
            <h3><div className="bullet">
              { product.price === 24  ? <>3</> : <>2</> }  
            </div>Paiement :</h3>
            <CheckoutForm onPaid={(paymentIntent) => onPaid(paymentIntent)} price={price} product={product} checkAddress={() => checkAddress()} products={products} packageType={form.data.package} />
          </>
        }
      </div>
    </div>
  );
};
