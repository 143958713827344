import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth/Auth';
import { setCookie } from '../../hooks/auth/session';
import { Link } from '../../hooks/router';
import Api from '../../services/Api';
import './iframeyearlist.scss';

export default function IFrameYearList ({ route }) {
  const [classes, setClasses] = useState([]);
  const auth = useAuth();

  const [semester, year] = route.params.slug.split('-');

  const confFirst = [{
    name: '1 - Écriture sainte',
    code: 'holy-scripture',
    subCategories: [{
      name: 'A - Parcours fondamental',
      code: 'fundamental-course'
    }, {
      name: 'B - Ancien Testament',
      code: 'old-testament'
    }, {
      name: 'C - Nouveau Testament',
      code: 'new-testament'
    }, {
      name: 'D - De l\'Ancien au Nouveau Testament',
      code: 'from-the-old-to-the-new-testament'
    }, {
      name: 'E - Langues bibliques',
      code: 'biblical-languages'
    }]
  }, {
    name: '2 - Théologie',
    code: 'theology',
    subCategories: [{
      name: 'A - Parcours fondamental',
      code: 'parcours-fundamental-theology'
    }, {
      name: 'B - Théologie fondamentale',
      code: 'fundamental-theology'
    }, {
      name: 'C - Théologie pastorale',
      code: 'pastoral-theology'
    }, {
      name: 'D - Théologie dogmatique',
      code: 'dogmatic-theology'
    }, {
      name: 'E - Théologie morale et questions anthropologiques',
      code: 'moral-theology-and-anthropological-questions'
    }, {
      name: 'F - Histoire',
      code: 'history'
    }]
  }, {
    name: '3 - Philosophie',
    code: 'philosophy',
    subCategories: [],
  }, {
    name: '4 - Judaïsme',
    code: 'judaism',
    subCategories: [],
  }, {
    name: '5 - Spiritualité, Art et Littérature',
    code: 'spirituality-art-literature',
    subCategories: [],
  }];

  const confSecond = [{
    name: '1 - Écriture sainte',
    code: 'holy-scripture',
    subCategories: [{
      name: 'A - Ancien Testament',
      code: 'old-testament'
    }, {
      name: 'B - Nouveau Testament',
      code: 'new-testament'
    }, {
      name: 'C - De l\'Ancien au Nouveau Testament',
      code: 'from-the-old-to-the-new-testament'
    }]
  }, {
    name: '2 - Théologie',
    code: 'theology',
    subCategories: [{
      name: 'A - Théologie fondamentale',
      code: 'parcours-fundamental-theology'
    }, {
      name: 'B - Théologie dogmatique',
      code: 'dogmatic-theology'
    }, {
      name: 'C - Théologie morale et questions anthropologiques',
      code: 'moral-theology-and-anthropological-questions'
    }, {
      name: 'D - Histoire',
      code: 'history'
    }]
  }, {
    name: '3 - Philosophie',
    code: 'philosophy',
    subCategories: [],
  }, {
    name: '4 - Judaïsme',
    code: 'judaism',
    subCategories: [],
  }, {
    name: '5 - Spiritualité, Art et Littérature',
    code: 'spirituality-art-literature',
    subCategories: [],
  }];

  useEffect(() => {
    setCookie('year-version', 'true');
    Api.get(`/classes`, { year, type: 'public', period: (semester === 'first' ? ['first-semester', 'annual'] : ['second-semester']), page: -1 }, null, true)
      .then(response => {
        setClasses(response.data);
      })
      .catch(error => {
        console.log(error);
        alert('Une erreur est survenue.');
      })
  }, [semester]);

  const wording = {
    first: {
      title: `COURS PUBLICS DU PREMIER SEMESTRE ${year}/${parseInt(year, 10)+1}`,
      cta: 'Voir les cours du second semestre',
      image: 'cours4.jpg',
      backToSchool: `lundi 19 septembre ${year}`
    },
    second: {
      title: `COURS PUBLICS DU SECOND SEMESTRE ${year}/${parseInt(year, 10)+1}`,
      cta: 'Voir les cours du premier semestre',
      image: 'cours-public-2esemestre-2019.jpg',
      backToSchool: `lundi 30 janvier ${parseInt(year, 10)+1}`
    }
  }

  return (
    <div className="iframelist">
      <div className="iframe">
        <div className="page">
          <div className="sub">Formation</div>
          <div className="title">{wording[semester].title}</div>
          <div className="desc">
            Les cours Publics du Collège des Bernardins accueillent toutes les personnes souhaitant découvrir la foi chrétienne ou l’approfondir.
          </div>
          {/* <p><span style={{ fontSize: '18px' }}><strong><span style={{ color: '#e74c3c' }}>À LIRE ATTENTIVEMENT AVANT DE VOUS INSCRIRE !</span></strong></span></p>

          <p><strong><span style={{ color: '#e74c3c' }}>Le Collège des Bernardins fait évoluer ses outils, et propose une nouvelle plateforme numérique dédiée, qui va s’enrichir au fur et à mesure de nouveaux services et enrichir votre expérience d’étudiant connecté.</span></strong></p>

          <p><strong><span style={{ color: '#e74c3c' }}>Pour vous inscrire, vous devez créer un nouveau compte pour accéder à votre espace étudiant et notamment à vos factures. Et ce, <u>même si vous possédiez déjà un compte</u>. Cela ne vous prendra que quelques minutes !</span></strong></p>

          <p><strong><span style={{ color: '#e74c3c' }}>Inscrivez-vous ou connectez-vous en cliquant sur les onglets à droite de la page (« Se connecter à mon compte étudiant » ou « Créer mon compte étudiant »)&nbsp;</span></strong></p>
          <br /> */}

          <Link to="iframe-year" params={{ slug: `${semester === 'first' ? 'second' : 'first'}-${year}-${parseInt(year, 10) + 1}` }}>
            {wording[semester].cta}
          </Link>
          <div className="date">
            Rentrée des cours : {wording[semester].backToSchool}
          </div>
          <img src={`/images/${wording[semester].image}`} alt="" style={{ maxWidth: '100%' }} />
          <br/><br/>
          <br />
          Pour vous inscrire, cliquez sur l'intitulé de cours de votre choix :

          {
            (semester === 'first' ? confFirst : confSecond).map(category => (
              <div className="category">
                <div className="title">
                  {category.name}
                </div>
                { 
                  category.subCategories.map(subCategory => (
                    <div className="sub-category">
                      <div className="title">
                        {subCategory.name}
                      </div>
                      <div className="classes">
                        {
                          classes.filter(cl => cl.category === category.code && cl.subCategory === subCategory.code).sort((a, b) => a.number - b.number).map(classe => (
                            <Link to="iframe-classe" params={{ slug: classe.id }} className="link">
                              {classe.number} - {classe.name}
                            </Link>
                          ))
                        }
                      </div>
                    </div>
                  ))
                }
                { category.subCategories.length === 0 &&
                  <div className="classes">
                    {
                      classes.filter(cl => cl.category === category.code).sort((a, b) => a.number - b.number).map(classe => (
                        <Link to="iframe-classe" params={{ slug: classe.id }} className="link">
                          {classe.number} - {classe.name}
                        </Link>
                      ))
                    }
                  </div>
                }
              </div>
            ))
          }
          
        </div>
      </div>
      <div className="menu">
        { auth.isAuthenticated ? (
          
          <>
            { auth.user ? (
              <>
                {auth.user.firstname} {auth.user.lastname}
                <br/><br/>
                <Link className="light" to="dashboard" target>Mon compte étudiant</Link>
                <span style={{ cursor: 'pointer', textDecoration: 'underline', marginTop: '5px', display: 'block' }} onClick={() => auth.signout()}>Se déconnecter</span>
              </>
            ) : (
              <>Chargement</>
            )}
            <br />
          </>
        ) : (
          <>
            <Link to="dashboard" query={{ redirect: encodeURIComponent(`iframe/classes/${semester}-${year}-${parseInt(year, 10) + 1}`) }}>
              Se connecter à mon compte étudiant
            </Link>
            <Link to="register" query={{ redirect: encodeURIComponent(`iframe/classes/${semester}-${year}-${parseInt(year, 10) + 1}`) }}>
              Créer mon compte étudiant
            </Link>
          </>
        )}
        <hr/>
        <br />
        <Link to="checkout">Voir mon panier</Link>
        <br/>
        <Link to="iframe-year" params={{ slug: `first-${year}-${parseInt(year, 10) + 1}` }}>
          Cours du 1er semestre
        </Link>
        <Link to="iframe-year" params={{ slug: `second-${year}-${parseInt(year, 10) + 1}` }}>
          Cours du 2nd semestre
        </Link>
        <Link to="iframe-ccdej-year" params={{ year }}>
          Cours CCDEJ
        </Link>
      </div>
    </div>
  );
};
